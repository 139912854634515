import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Divider,
  Button,
  Icon,
  Message,
  Confirm,
  Form,
  Dropdown,
} from "semantic-ui-react";
import * as deliveryAddressReducer from "./deliveryAddress.reducer";
import Loader from "../../../components/Loader";
import "./deliveryAddress.css";
import Autocomplete from "react-google-autocomplete";
import "./deliveryAddress.css";
import AddressDetails from "../../../components/AddressDetails";
import { Segment } from "semantic-ui-react";
import { Control, Errors } from "react-redux-form";
import { showSuccessToast, showErrorToast } from "../../../utils/toastUtils";
import _ from "lodash";
import MetaData from "../../../components/MetaData";
import {
  SITE_KEY,
  industries,
  countries,
  states,
  customerRoles,
  TERMS_CONDITION_LINK,
  APP_NAME,
  allcCountryList,
} from "../../../constants/appConstants";

class DeliverAddresses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null,
      loading: false,
      customInput: "",
      placeName: "",
      width: 0,
      countryErrorFlag: false,
      stateErrorFlag: false,
      stateId: "",
      countryId: "",
      loadingText: "Please wait",
    };
  }

  componentDidMount() {
    this.props.actionResetState("forms.createContactForm");
    // this.isCaptchaReady();
  }

  componentWillMount() {
    this.getAddressFromServer();
  }
  getAddressFromServer = async () => {
    this.setState({ loading: true });
    await this.props.actionGetAccountDetails2();
    this.setState({ loading: false });
  };

  goBack = () => {
    // this.props.history.push("/profile");
    this.props.history.goBack();
  };

  getAllAddress = () => {
    const { deliveryAddress } = this.props.deliveryAddress;
    const allAddress = [...deliveryAddress];
    return allAddress;
  };

  updateDbCall = async (allAddress) => {
    this.updateState({ loading: true });
    await this.props.actionUpdateDeliveryAddress({
      deliveryAddress: allAddress,
    });
    await this.props.actionGetAccountDetails();
    this.updateState({ loading: false });
  };

  isAddressValid = (newAddress) => {
    if (!newAddress.street.trim() && !newAddress.postalCode.trim()) {
      showErrorToast("Enter more details in your address. Eg Street Number");
      return false;
    }
    // if (_.isEmpty(newAddress.postalCode)) {
    //   showErrorToast("Enter more details in your address. Eg Postal Code");
    //   return false;
    // }

    const deliveryAddress = this.getAllAddress();
    const b = _.pick(newAddress, ["street", "postalCode"]);
    const filteredAddress = _.filter(deliveryAddress, (address) => {
      const a = _.pick(address, ["street", "postalCode"]);
      return _.isEqual(a, b);
    });

    if (filteredAddress.length > 0) {
      showErrorToast("Duplicate address.");
      return false;
    }
    return true;
  };

  clearAddress = () => {
    this.updateState({ address: null, customInput: "", placeName: "" });
  };

  addAddressHandler = async (newAddress, countries, states) => {
    if (!this.isAddressValid(newAddress)) {
      return;
    } else {
      const { customInput, placeName } = this.state;
      newAddress.name = placeName;
      newAddress.street = newAddress.street;
      newAddress.street2 = newAddress.street2;
      // if (placeName) {
      //   newAddress.street = placeName;
      //   // newAddress.street = placeName + ", " + newAddress.street;
      // } else {
      //   newAddress.street = newAddress.placeName;
      // }

      const allCountries = allcCountryList;

      const country = allCountries.filter(
        (m) => m.name.toLowerCase() === newAddress.country.toLowerCase()
      );
      const _state = states.filter(
        (m) => m.text.toLowerCase() === newAddress.state.toLowerCase()
      );

      newAddress.countryId = country.length > 0 && country[0].id;
      newAddress.zip = newAddress.postalCode;
      let allAddress = this.getAllAddress() || [];
      newAddress.isPrimary = allAddress.length === 0 ? true : false;
      this.setState({ loading: true });
      const result = await this.props.actionAddNewDeliveryAddress(newAddress);
      const { status } = result;
      if (status !== 200) {
        showErrorToast("Something went wrong, please try again");
      } else {
        showSuccessToast(
          <div>
            <Icon name="address book" />
            Address added
          </div>
        );
      }
      this.clearAddress();
      await this.getAddressFromServer();
      this.setState({ loading: false });
    }
  };

  closeConfirmation = async (item) => {
    this.setState({
      confirmDelete: false,
      itemToDelete: null,
    });
  };

  deleteAddressHandler = async (id, sfId) => {
    const { deliveryAddress } = this.props.deliveryAddress;
    const allAddress = [...deliveryAddress];

    const addressToBeDeleted = allAddress[id];
    const { isPrimary } = addressToBeDeleted;
    if (isPrimary) {
      showErrorToast(
        "Can not delete a default address. Change the Default and then delete the address."
      );
      return;
    }

    this.setState({
      confirmDelete: true,
      itemToDelete: addressToBeDeleted.id,
    });
  };

  deleteAddress = async (id) => {
    this.setState({ loading: true });
    const { itemToDelete } = this.state;
    this.closeConfirmation();
    const result = await this.props.actionDeleteDeliveryAddress(itemToDelete);
    const { status, response } = result;

    if (response && response.data.statusCode >= 400)
      showErrorToast(response.data.description);
    else if (!(status >= 200 && status <= 299)) {
      showErrorToast("Something went wrong, please try again");
    } else {
      showSuccessToast(
        <div>
          <Icon name="address book" />
          Address deleted
        </div>
      );
    }
    await this.getAddressFromServer();
    this.setState({ loading: false });
  };

  makePrimaryAddressHandler = async (address) => {
    // const addressToSend = { ...address };
    // addressToSend.isPrimary = true;
    this.setState({ loading: true });
    await this.props.actionUpdateDeliveryAddress(address);
    this.getAddressFromServer();
  };

  updateState = (state) => {
    this.setState(state);
  };

  getDeliveryAddress = (deliveryAddress) => {
    if (window._.isEmpty(deliveryAddress)) {
      return null;
    }

    const { user } = this.props.auth;

    const addressComponent = deliveryAddress.map((value, key) => {
      let customClass = "";
      customClass = value.isPrimary
        ? "address-column primary"
        : "address-column";
      return (
        <Grid.Column
          width={5}
          computer={5}
          mobile={16}
          className={customClass}
          key={key}
        >
          <AddressDetails
            index={key}
            address={value}
            options={true}
            deleteAddressHandler={this.deleteAddressHandler}
            makePrimary={this.makePrimaryAddressHandler}
            editContactInfo={this.editContactInfoHandler}
          />
        </Grid.Column>
      );
    });

    return addressComponent;
  };

  onAddressSelection = (place) => {
    const address = this.getCitySuburb(place);

    this.setState({ address });
  };

  getCitySuburb = (place) => {
    let address = {
      street_number: "",
      route: "",
      subLocality_level_1: "",
      locality: "",
      administrative_area_level_1: "",
      country: "",
      postal_code: "",
    };

    place.address_components.forEach((component) => {
      const types = component.types;

      if (types.includes("street_number")) {
        address.street_number = component.long_name;
      } else if (types.includes("route")) {
        address.route = component.long_name;
      } else if (types.includes("sublocality_level_1")) {
        address.subLocality_level_1 = component.long_name;
      } else if (types.includes("locality")) {
        address.locality = component.short_name;
      } else if (types.includes("administrative_area_level_1")) {
        address.administrative_area_level_1 = component.long_name;
      } else if (types.includes("country")) {
        address.country = component.long_name;
      } else if (types.includes("postal_code")) {
        address.postal_code = component.long_name;
      }
    });

    if (address.subLocality_level_1 === "") {
      address.subLocality_level_1 = address.locality;
    }

    return {
      street: address.street_number,
      street2: address.route,
      suburb: address.subLocality_level_1,
      city: address.locality,
      state: address.administrative_area_level_1,
      country: address.country,
      postalCode: address.postal_code,
    };
  };

  customAddressOnChangeHandler = (e) => {
    const { target } = e;
    const { value } = target;
    this.setState({ placeName: value });
  };

  countryChangeHandler = (e, data) => {
    this.updateState({ countryErrorFlag: false });
    const { value } = data;
    if (window._.isEmpty(value)) {
      this.updateState({ countryErrorFlag: true });
    }

    const formValue = {
      ...this.props.forms.createContactForm,
      country: value,
      countryId: value,
    };
    this.props.actionSetFormValue(formValue);
  };

  stateChangeHandler = (e, data) => {
    this.updateState({ stateErrorFlag: false });
    const { value } = data;
    if (window._.isEmpty(value)) {
      this.updateState({ stateErrorFlag: true });
    }

    const formValue = {
      ...this.props.forms.createContactForm,
      state: value,
    };
    this.props.actionSetFormValue(formValue);
  };

  isFormValid = () => {
    // const { mobileErrorFlag } = this.state;
    // const { mobileNumber } = this.props.forms.createAccountForm;
    // if (mobileErrorFlag) {
    //   return false;
    // }
    // if (!mobileNumber.length) {
    //   return false;
    // }
    return true;
  };

  HandleCreate = async () => {
    this.setState({ errorFlag: false, errorMessage: "" });
    const { createContactForm } = this.props.forms;
    const { accountInfo } = this.props.auth;
    let createContact = Object.assign(
      {},
      {
        type: 0,
        name: accountInfo.firstName + " " + accountInfo.lastName,
        email: "",
        street: createContactForm.street,
        street2: createContactForm.street2,
        city: createContactForm.city,
        stateId: +createContactForm.state,
        zip: createContactForm.zip,
        countryId: +createContactForm.country,
        id: +createContactForm.id,
      }
    );

    // const { iAgree } = this.state;
    const validFlag = this.isFormValid();

    // if (!this.passwordValidation()) {
    //   this.updateState({
    //     errorFlag: true,
    //     errorMessage: "Password and Confirm password do not match",
    //   });
    //   return;
    // }

    // if (!validFlag) {
    //   this.updateState({
    //     errorFlag: true,
    //     errorMessage: "Please insert your mobile number",
    //   });
    //   return;
    // }
    // if (window._.isEmpty(createAccountForm.captchaToken)) {
    //   this.updateState({
    //     errorFlag: true,
    //     errorMessage: "Please check the captcha option",
    //   });
    //   return;
    // }

    this.updateState({
      loading: true,
    });

    if (createContact.id > 0)
      await this.props.actionUpdateContact(createContact);
    else await this.props.actionCreateContact(createContact);

    const { response } = this.props.deliveryAddress;
    if (response) {
      if (response.status === 424) {
        this.updateState({
          errorFlag: true,
          errorMessage: "Something went wrong, please try it again",
        });
      }
      if (response.status === 409) {
        this.updateState({
          errorFlag: true,
          errorMessage:
            "User with this Email already exist. Try Logging in or contact system admin",
        });
      }
      if (response.status === 403) {
        this.updateState({
          errorFlag: true,
          errorMessage: "Forbidden",
        });
      }
      if (response.status === 412) {
        this.updateState({
          errorFlag: true,
          errorMessage:
            "This company name is already registered with BOA, please select other company name or in case of queries get in touch with BOA. ",
        });
      }
      if (response.status === 400) {
        this.updateState({
          errorFlag: true,
          errorMessage: response.data.description,
        });
      }

      if (response.status === 200 || response.status === 202) {
        this.props.actionResetState("forms.createContactForm");
        this.props.actionSetEditContactFlag(false);
        this.getAddressFromServer();
        window.scrollTo(0, 0);
        this.updateState({
          stateId: "",
          countryId: "",
          success: true,
        });
      }
    }

    this.updateState({
      loading: false,
    });
  };

  setContactForm = (contact) => {
    // const { deliveryAddress } = this.props.deliveryAddress;
    const formData = this.getContactFormData(contact);
    this.updateState({
      stateId: formData.state,
      countryId: formData.country,
    });
    this.props.actionSetContactInfoForm(formData);
  };

  editContactInfoHandler = (contact) => {
    this.setContactForm(contact);
    this.props.actionSetEditContactFlag(true);
  };

  cancelEdit = () => {
    this.unSetForm();
    this.props.actionSetEditContactFlag(false);
  };

  unSetForm = () => {
    const data = {
      type: 0,
      isPrimary: false,
      id: "",
      name: "",
      email: "",
      phone: "",
      mobile: "",
      street: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    };
    this.props.actionSetContactInfoForm(data);
  };

  clearForm = () => {
    const data = {
      type: 0,
      isPrimary: false,
      id: "",
      name: "",
      email: "",
      phone: "",
      mobile: "",
      street: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    };
    this.props.actionSetContactInfoForm(data);
  };

  getContactFormData = (contact) => {
    const country = countries.find((x) => x.text === contact.country);
    const state = states.find((x) => x.text === contact.state);

    const formData = {
      type: 0,
      isPrimary: contact.isPrimary,
      id: contact.id,
      name: contact.name,
      email: contact.email,
      phone: contact.phone,
      mobile: contact.mobileNumber,
      street: contact.placeName,
      street2: contact.street,
      city: contact.city,
      state: state.value,
      zip: contact.postalCode,
      country: country.value,
    };
    return formData;
  };

  render() {
    const { defaultContactAddressId } =
      this.props.auth.accountInfo || this.props.auth.user;
    const { deliveryAddress } = this.props.deliveryAddress;
    const { createContactForm } = this.props.forms;
    const { editContactFlag } = this.props;
    const {
      // loading,
      customInput,
      placeName,
      width,
      countryErrorFlag,
      stateErrorFlag,
      stateId,
      countryId,
      loadingText,
      loading = true,
    } = this.state;

    const countryList = JSON.parse(localStorage.getItem("countries"));

    let _deliveryAddress = [];
    if (deliveryAddress.length > 0) {
      deliveryAddress.map((item) => {
        _deliveryAddress.push({
          placeName: item.name,
          street: item.street,
          street2: item.street2,
          state: item.state && item.state.name,
          suburb: item.suburb,
          postalCode: item.zip,
          country: item.country && item.country.name,
          city: item.city,
          isPrimary: item.id === defaultContactAddressId,
          salesforceId: null,
          id: item.id,
          // name: item.name,
          // email: item.email,
          // mobileNumber: item.mobileNumber,
          // phone: item.phone,
        });
      });
    }
    const deliverAddressComponent = this.getDeliveryAddress(_deliveryAddress);
    const count = deliveryAddress && deliveryAddress.length;

    const customAddressJSX = (
      <div>
        <Form>
          <Form.Field>
            <input
              type="text"
              name="address"
              placeholder="Enter place name (Optional)"
              maxLength="40"
              // value={customInput}
              value={placeName}
              onChange={this.customAddressOnChangeHandler}
            />
          </Form.Field>
        </Form>
      </div>
    );

    const hiddenForm = (
      <div>
        <Form.Field>
          <Control
            type="hidden"
            model="forms.createContactForm.id"
            className="input-box"
          />
        </Form.Field>
        <Form.Field>
          <Control
            type="hidden"
            model="forms.createContactForm.name"
            className="input-box"
          />
        </Form.Field>
        <Form.Field>
          <Control
            type="hidden"
            model="forms.createContactForm.email"
            className="input-box"
          />
        </Form.Field>
      </div>
    );

    return (
      <React.Fragment>
        <MetaData title="Delivery addresses" />
        {loading && <Loader text={loadingText} />}
        <Grid className="search-grid">
          <Grid.Column width={16}>
            <Button
              size="small"
              onClick={this.goBack}
              icon
              labelPosition="left"
              className="button-float"
            >
              <Icon name="left arrow" />
              Go back
            </Button>
            <h3 className="address-title">
              <Icon name="address book" /> Manage delivery addresses
            </h3>
            <Divider />
          </Grid.Column>
          <Grid.Column width={16} computer={8} mobile={16}>
            <label>
              <h4>Add new address</h4>
            </label>
            {count < 20 && (
              <div className="google-autocomplete-container">
                {!this.state.address && (
                  <Autocomplete
                    required
                    style={{ width: "100%" }}
                    placeholder="Start typing your address"
                    className="input-box"
                    onPlaceSelected={(place) => {
                      this.onAddressSelection(place);
                    }}
                    types={["geocode"]}
                    componentRestrictions={{
                      country: countryList,
                    }}
                  />
                )}
              </div>
            )}
            {count === 20 && (
              <Message>
                You have reached the maximum limit of addresses. Try deleting
                old addresses before you can add new ones.
              </Message>
            )}

            {this.state.address && (
              <Segment>
                {customAddressJSX}
                <AddressDetails
                  address={this.state.address}
                  options={false}
                  add={true}
                  addHandler={this.addAddressHandler}
                  cancelHandler={this.clearAddress}
                />
              </Segment>
            )}
          </Grid.Column>
          <Grid.Column width={10} computer={8} mobile={16}>
            <Grid columns="equal" className="address-grid">
              {deliverAddressComponent}
            </Grid>
          </Grid.Column>
        </Grid>

        <Confirm
          open={this.state.confirmDelete}
          cancelButton="Never mind"
          confirmButton="Let's do it"
          content="Are you sure you want to delete the address ? "
          onCancel={this.closeConfirmation}
          onConfirm={this.deleteAddress}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forms: state.forms,
    auth: state.auth,
    deliveryAddress: state.deliveryAddress,
    editContactFlag: state.deliveryAddress.editContactFlag,
    response: state.response,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(deliveryAddressReducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeliverAddresses)
);
