// Third party components
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "semantic-ui-react";
import axios from "axios";
import GoogleMapReact from "google-map-react";

// Project Components
import { PrivateRoute } from "../../components/Routes/PrivateRoute";
import { PublicRoute } from "../../components/Routes/PublicRoute";
import PageNotFound from "../../components/ErrorPages/PageNotFound";
import Footer from "../../components/Footer";
import { UnAuthenticatedRoutes, AuthenticatedRoutes } from "./routes";
import PublicHeader from "../../components/Headers/PublicHeader";
import UserHeader from "../../components/UserHeader";
import * as authReducer from "../Auth/auth.reducer";
import * as cartReducer from "../Cart/cart.reducer";
import "./app.css";
import "../../index.css";
import { refreshToken } from "../Auth/auth.api";
import Slider from "react-slick";
import { FadeIn } from "react-animated-components";
import banner1 from "../../assets/images/banner/banner-1.png";
import banner2 from "../../assets/images/banner/banner-2.png";
import banner3 from "../../assets/images/banner/banner-3.png";
import banner4 from "../../assets/images/banner/banner-4.png";
import { useWindowSize } from "../../utils/utils";
import BOApodImage from "../../assets/images/pod-marker.png";
import { apiGetBOApods, apiGetBanners } from "../Settings/settings.api";
import ReactGA from "react-ga4";
import { env } from "../../env";
import { apiGetUserCurrentStockLocation } from "../Cart/cart.api";
import EnterpriseHomeComponent from "../Home/Enterprise";
import CallbackComponent from "../Callback/callback";
import SendFeedback from "../../components/SendFeedback/SendFeedback";

const defaultRoutes = [
  <Route key="pageNotFound" component={PageNotFound} />,
  <Route key="callback" component={CallbackComponent} />,
];

const images = [banner1, banner2, banner3, banner4];

const settings = {
  accessibility: true,
  arrows: false,
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

const unAuthenticatedRouteComponents =
  UnAuthenticatedRoutes.map((route) => {
    return (
      <PublicRoute
        key={route.name}
        exact
        path={route.path}
        component={route.component}
      />
    );
  }) || [];

const authenticatedRouteComponents =
  AuthenticatedRoutes.map((route) => {
    return (
      <PrivateRoute
        key={route.name}
        exact
        path={route.path}
        component={route.component}
        isFacilities={route.isFacilities || false}
      />
    );
  }) || [];

const routes = [
  ...authenticatedRouteComponents,
  ...unAuthenticatedRouteComponents,
  ...defaultRoutes,
];

const TokenRefresher = ({ refreshTokenFunction }) => {
  useEffect(() => {
    // function to refresh token
    const refresh = async () => {
      try {
        // Call refresh token function here
        await refreshTokenFunction();
      } catch (error) {
        console.error("Error refreshing token:", error);
      }
    };

    // Call the refresh function initially
    refresh();

    // Interval to refresh token every 5 minutes (300000 milliseconds)
    const intervalId = setInterval(refresh, 300000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return null;
};

const UserApp = (props) => {
  const { isLoggedIn = false } = props.auth;
  const [countryCode, setCountryCode] = useState("");
  const [animateFade, setAnimateFade] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [podList, setPodList] = useState([]);
  const [myPodList, setMyPodList] = useState([]);
  const [podListData, setPodListData] = useState([]);
  const [banners, setBanners] = useState([]);
  const [zoom, setZoom] = useState(10);

  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  useEffect(() => {
    if (podList.length > 0) {
      const filteredArray = podList.filter(
        (item) => item.companyName === localStorage.getItem("companyName")
      );
      setPodListData(filteredArray);
    }
  }, [podList]);

  useEffect(() => {
    console.log(podListData);
  }, [podListData]);

  const getBOApodsData = async () => {
    try {
      const result = await apiGetBOApods({ lat: -36.862049, lng: 174.760567 });
      if (result) {
        setPodList(result);
      }
    } catch {}
  };

  const getBannersData = async (type) => {
    try {
      const result = await apiGetBanners(type);
      if (result) {
        setBanners(result);
      }
    } catch {}
  };

  const size = useWindowSize();

  useEffect(() => {
    if (size.width !== 0) {
      if (size.width <= 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [size]);

  useEffect(() => {
    if (size.width !== 0) {
      const screenWidth = localStorage.getItem("screenWidth");
      if (!screenWidth) {
        window.localStorage.setItem("screenWidth", size.width);
      }
      if (size.width <= 600) {
        if (window.location.pathname.includes("/home"))
          getBannersData("mobile");
      } else {
        if (window.location.pathname.includes("/home")) getBannersData("web");
      }
    }
  }, [window.location.pathname, size]);

  const getGeoInfo = () => {
    try {
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          if (data) setCountryCode(data.country_code);
        })
        .catch((error) => {
          // Handle the error
          console.error("Error:", error);
        });
    } catch {
      console.log("");
    }
  };

  async function init() {
    // google analytics
    // ReactGA.pageview(window.location.pathname + window.location.search);
    if (env === "production") ReactGA.initialize("G-EL2BYXLGXW");

    let url = document.location.href;
    url = new URL(url);
    const { searchParams } = url;
    const sessionIdInParam = searchParams.get("sessionID");
    if (sessionIdInParam) {
      // window.localStorage.setItem("sessionID", sessionIdInParam);
      window.localStorage.setItem("isAdminLogin", true);
      window.location.href = "/";
      return;
    }
    const sessionExists = window.localStorage.getItem("sessionID");
    let gaOptions = {
      debug: true,
    };
    if (sessionExists) {
      if (window.location.pathname !== "/profile")
        await props.actionGetProfileData();
      gaOptions = { userId: sessionExists };
    }
    // google analytics Mapped to Johnson's Account
    // ReactGA.initialize('UA-125124319-1', gaOptions);
    const isMigrated = window.localStorage.getItem("isMigrated");
    if (isMigrated === "true") props.history.push("/profile/changePassword");
  }

  async function sessionCheck() {
    const sessionExists = window.localStorage.getItem("sessionID");

    if (sessionExists) {
      // await refreshToken();
    }
  }

  useEffect(() => {
    init();
    const currentCountryCode = localStorage.getItem("countryCode");
    if (!currentCountryCode || currentCountryCode == "") {
      getGeoInfo();
    }
  }, []);

  useEffect(() => {
    if (countryCode && countryCode != "") {
      localStorage.setItem("countryCode", countryCode);
    }
  }, [countryCode]);

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionCheck();
    if (isLoggedIn)
      if (window.location.pathname !== "/cart") props.actionLoadCart();
    if (env === "production") {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
    setAnimateFade(!animateFade);
    if (window.location.pathname.includes("/home")) {
      getBOApodsData();
    }
  }, [window.location.pathname]);

  const header = isLoggedIn ? (
    <UserHeader logout={props.logout} history={props.history} />
  ) : null;

  const Marker = ({ pod, zoom }) => {
    const markerSize = zoom * 8;

    return (
      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <img
          src={BOApodImage}
          alt="Marker"
          style={{ width: `${markerSize}px`, cursor: "pointer", zIndex: -1 }}
          onClick={() => {
            setSelectedPod(pod);
            setLocation({
              lat: pod?.position.latitude,
              lng: pod?.position.longitude,
            });
            setIsVisible(true);
          }}
        />
      </div>
    );
  };

  const goToPage = (text) => {
    props.history.push(`/${text}`);
  };

  const refreshTokenFunction = async () => {
    await refreshToken();
    await new Promise((resolve) => setTimeout(resolve, 5000)); // Simulated 2 seconds delay
  };

  return (
    <React.Fragment>
      <header>{header}</header>
      <div className="main" style={{ paddingBottom: isLoggedIn ? 30 : 0 }}>
        {isLoggedIn ? (
          <div>
            <TokenRefresher refreshTokenFunction={refreshTokenFunction} />
            <FadeIn key={animateFade ? "fadeIn" : "fadeOut"} durationMs={600}>
              {window.location.pathname.includes("/home") ? (
                <>
                  <Slider {...settings} style={{ marginTop: -20 }}>
                    {banners.length > 0 &&
                      banners.map((image, index) => (
                        <div key={index}>
                          <a href={image.targetUrl} target="_blank">
                            <img
                              src={image.sourceUrl}
                              alt={`Image ${index + 1}`}
                              style={{ width: "100%", height: "auto" }}
                            />
                          </a>
                        </div>
                      ))}
                  </Slider>
                  <EnterpriseHomeComponent
                    goToPage={(page) => goToPage(page)}
                  />
                  <SendFeedback />
                </>
              ) : null}
              {/* {window.location.pathname.includes("/home") &&
              accountType === 1 ? (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: 30,
                    marginBottom: 10,
                  }}
                >
                  <Button
                    size="small"
                    style={{
                      backgroundColor: "rgb(102, 204, 35)",
                      color: "#fff",
                    }}
                    onClick={() => props.history.push("/company-ranking")}
                  >
                    Check Your Hose Assembly Comp Ranking!
                  </Button>
                </div>
              ) : null} */}
              <Container
                className="main-container"
                style={{
                  marginTop: 30,
                  marginLeft: isMobile ? "0em!important" : "1rem",
                  marginRight: isMobile ? "0em!important" : "1rem",
                }}
              >
                <Switch>{routes}</Switch>
              </Container>
            </FadeIn>
          </div>
        ) : (
          <Switch>{routes}</Switch>
        )}
      </div>
      {window.location.pathname.includes("/home") ? (
        <div
          style={{
            height: "600px",
            width: "100%",
            borderTop: "10px solid rgb(102, 204, 35)",
            cursor: "pointer",
            pointerEvents:
              localStorage.getItem("boahubFindPod") !== "true"
                ? "none"
                : "auto",
          }}
          onClick={() => {
            if (localStorage.getItem("boahubFindPod") === "true")
              props.history.push("/find-boapod");
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={{
              lat: -36.862049,
              lng: 174.760567,
            }}
            defaultZoom={11}
            zoom={11}
            center={{
              lat: -36.862049,
              lng: 174.760567,
            }}
            onChange={({ zoom }) => handleZoomChange(zoom)}
          >
            {podListData.map((pod) => {
              return (
                <Marker
                  lat={pod?.position?.latitude}
                  lng={pod?.position?.longitude}
                  pod={pod}
                  zoom={zoom}
                />
              );
            })}
          </GoogleMapReact>
        </div>
      ) : null}
      {isLoggedIn && <Footer />}
      <ToastContainer autoClose={2000} />
      {/* <Modal size="mini" open={openSelectStockLocation}>
        <Modal.Header>YOUR STOCK LOCATION HAS CHANGED</Modal.Header>
        <Modal.Content>
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            <p>
              Please choose the correct stock location for this order
            </p>
          </div>
          <Form size="mini">
            <Form.Field>
              <Dropdown
                search
                placeholder="Select Stock Location"
                selection
                options={stocklocationList}
                value={formData?.boahubLocationId}
                onChange={(e, data) =>
                  handleChange("boahubLocationId", data.value)
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => {
              onSubmitJob(
                formData?.jobStatusId === 3 ? 3 : formData?.jobStatusId + 1,
                true
              );
            }}
          >
            SAVE
          </Button>
        </Modal.Actions>
      </Modal> */}
    </React.Fragment>
  );
};

UserApp.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const reducers = { ...authReducer, ...cartReducer };

const mapDispatchToProps = (dispatch) => bindActionCreators(reducers, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserApp)
);
