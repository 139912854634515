export const tempData = {
  id: "",
  jobNumber: "",
  companyName: "",
  contactName: "",
  contactNumber: "",
  contactEmail: "",
  customerPurchaseOrderNumber: "",
  customerLocation: "",
  locationLat: null,
  locationLng: null,
  urgencyId: null,
  assetTextId: "",
  assetSerialNumber: "",
  makeId: null,
  equipmentTypeId: null,
  assetId: null,
  otherAssetDetails: null,
  assetPropValue: "",
  assetPropUnit: "hrs",
  jobDesc: "",
  jobImageDetails: null,
  jobStatusId: 1,
  distanceTravelled: null,
  hoursLabour: null,
  hoseMaker: [],
  makeName: "",
  equipmentTypeName: "",
  model: "",
  saveContactDetails: true,
  customer: {},
  customerContact: {},
  selectCustomer: true,
  selectContact: true,
};

export const jobStatusList = [
  {
    key: 6,
    value: 6,
    text: "Active",
    color: "black",
  },
  // {
  //   key: 0,
  //   value: 0,
  //   text: "Draft",
  //   color: "#ced4db",
  // },
  {
    key: 1,
    value: 1,
    text: "New Job Request",
    color: "#ff8f8f",
  },
  {
    key: 2,
    value: 2,
    text: "Work In Progress",
    color: "#ffdda6",
  },
  {
    key: 3,
    value: 3,
    text: "Completed",
    jobStatusDesc: "Completed",
    color: "#c4f8c8",
  },
  {
    key: 4,
    value: 4,
    text: "Invoiced",
    color: "#3482bf",
  },
  {
    key: 5,
    value: 5,
    text: "Archived",
    color: "#ced4db",
  },
];

export const hoseMakerTempData = {
  step1_Hose: 0,
  step1_Barcode: "",
  step1_Name: "",
  step2_LHF: 0,
  step2_Barcode: "",
  step2_Name: "",
  step3_LFerrule: 0,
  step3_Barcode: "",
  step3_Name: "",
  step4_RHF: 0,
  step4_Barcode: "",
  step4_Name: "",
  step5_RFerrule: 0,
  step5_Barcode: "",
  step5_Name: "",
  quantity: 1,
  orientation: 0,
  oal: 0,
  positionId: 0,
  others: [],
  bomAssembly: {
    lfActualCrimpSize: 0,
    rfActualCrimpSize: 0,
    isMarkedPushedOnCorrectly: false,
    image1: "",
    image2: "",
    image3: "",
    image4: "",
  },
};
