import axios from "axios";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const apiGetMyAssetsList = (search) => {
  const data = {
    SearchParam: search !== "" ? search : null,
  };
  return axios
    .get("/api/Assets/assets-list", { params: data })
    .then((result) => {
      if (result) {
        if (result.data) {
          const data = JSON.parse(result.data.result);
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              sortArrayByAssetId(data[key]);
            }
          }
          console.log(data);
          return data;
        }
      }
      return false;
    });
};

function sortArrayByAssetId(arr) {
  arr.sort((a, b) => {
    const assetIdA = a.AssetId;
    const assetIdB = b.AssetId;

    // Use localeCompare to perform a case-insensitive string comparison
    return assetIdA.localeCompare(assetIdB, undefined, { sensitivity: "base" });
  });
}

export const apiGetAsset = (id) => {
  const payload = {
    id: id,
  };
  return axios.get(`/api/Assets/asset`, { params: payload }).then((result) => {
    if (result && result.data) {
      return result.data;
    }
    return false;
  });
};

export const apiAssetsSearch = (searchValue) => {
  return axios
    .get(`/api/Jobbing/assets?search=${searchValue}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.map((item) => {
            const fullDescription = item.displayName.split(" | ");
            item.title = fullDescription[2];
            item.description = fullDescription[1];
            item.price = "Make: " + fullDescription[0];
          });
        }
        return result.data;
      }
      return false;
    });
};

export const apiGetHosePositions = (equipmentTypeName) => {
  return axios
    .get(`/api/Jobbing/hose/positions/${equipmentTypeName}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          if (result.data.positions) {
            result.data.positions.map((item) => {
              item.text = item.name;
              item.value = item.id;
            });
            return result.data;
          }
        }
      }
      return false;
    });
};

export const apiGetUsersListWithCurrent = () => {
  return axios.get("/api/user/my-users-with-current").then((result) => {
    if (result) {
      if (result.data) {
        result.data.sort((a, b) => a.fullName.localeCompare(b.fullName));
        result.data.map((item) => {
          item.text = item.fullName;
          item.value = item.email;
        });
        const filteredList = result.data.filter(
          (obj) => obj.userTypeName !== null && obj.isAccepted
        );
        return filteredList;
      }
    }
    return false;
  });
};

export const apiGetIndustryList = () => {
  return axios.get("/api/Assets/asset-categories").then((result) => {
    if (result) {
      if (result.data) {
        result.data.map((item) => {
          item.text = item.category;
          item.value = item.category;
        });
        return result.data;
      }
    }
    return false;
  });
};

export const apiGetAssetLocationsList = () => {
  return axios.get("/api/Assets/asset-locations").then((result) => {
    if (result) {
      if (result.data) {
        result.data.sort((a, b) =>
          a.locationName.localeCompare(b.locationName)
        );
        result.data.map((item) => {
          item.text = item.locationName;
          item.value = item.id;
        });
        return result.data;
      }
    }
    return false;
  });
};

export const apiAddAsset = async (data) => {
  const companyId = Number(localStorage.getItem("companyId"));
  data.year = Number(data.year);
  data.parentId = companyId;
  data.connectedAssets = [];
  data.connectedAssetsIds.map((value) => {
    data.connectedAssets.push({ assetId: value, assetDisplayName: "" });
  });
  if (data.otherAssetDetails === "") data.otherAssetDetails = null;
  if (data.otherAssetDetails !== null) {
    data.assetDbId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
  }
  return axios
    .post(`/api/Assets`, data)
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiUpdateAsset = async (data) => {
  const companyId = Number(localStorage.getItem("companyId"));
  data.year = Number(data.year);
  data.parentId = companyId;
  data.connectedAssets = [];
  data.connectedAssetsIds.map((value) => {
    data.connectedAssets.push({ assetId: value, assetDisplayName: "" });
  });
  if (data.otherAssetDetails === "") data.otherAssetDetails = null;
  if (data.otherAssetDetails !== null) {
    data.assetDbId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
  }
  return axios
    .put(`/api/Assets`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiDeleteAsset = async (data) => {
  const payload = {
    assetId: data.id,
  };
  return axios
    .delete(`/api/Assets/asset`, { params: payload })
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiGetAssetCategoryList = () => {
  return axios.get("/api/Assets/asset-categories").then((result) => {
    if (result) {
      if (result.data) {
        return result.data;
      }
    }
    return false;
  });
};

export const apiSetCategory = async (category) => {
  const data = {
    defaultAssetCategory: category,
  };
  return axios
    .put(`/api/Assets/set-user-default-category`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiAutoSaveAssetImage = async (data, id) => {
  const formData = new FormData();

  formData.append("file", data);
  return axios
    .post(`/api/Assets/upload-asset-image?id=${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiAutoRemoveAssetImage = async (id) => {
  return axios
    .delete(`/api/Assets/remove-asset-image?assetId=${id}`)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};

export const apiAutoSaveAssetChecklist = async (data, id) => {
  const formData = new FormData();

  formData.append("file", data);
  return axios
    .post(`/api/Assets/upload-asset-prestart?id=${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiAutoRemoveAssetChecklist = async (id) => {
  return axios
    .delete(`/api/Assets/delete-asset-prestart?id=${id}`)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};

export const apiUploadFile = async (file, id) => {
  const formDataParam = new FormData();

  formDataParam.append("file", file);
  return axios
    .post(`/api/Assets/upload-asset-file-multi?assetId=${id}`, formDataParam, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      if (result && result.data) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      console.log(error);
      if (error.response.data) {
        console.log(error.response.data);
        const data = error.response.data;
        showErrorToast(data?.message);
        if (data.statusCode === 400) return false;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiDeleteFile = async (fileId, id) => {
  const payload = {
    assetId: id,
    fileId: fileId,
  };
  return axios
    .delete(`/api/Assets/delete-asset-file`, { params: payload })
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiGetBOApodsList = (location) => {
  const data = {
    myLatitude: location.lat,
    myLongitude: location.lng,
    PageNo: 1,
    Limit: 1000,
  };
  return axios.get("/api/BOApod/fetch", { params: data }).then((result) => {
    if (result) {
      if (result.data) {
        const filteredPods = result.data.records.filter(
          (obj) =>
            obj.uniqueId !== "BOA-NEW" &&
            obj.driverName !== "" &&
            obj.driverName !== null
        );
        filteredPods.map((item) => {
          item.text = `${item.companyName} - ${item.driverName} | ${Math.round(
            item?.position?.distance
          )}km`;
          item.value = item;
        });
        return filteredPods;
      }
    }
    return false;
  });
};

export const apiGetMyBOAPodListData = () => {
  return axios.get("/api/BOApod/my-boapods").then((result) => {
    if (result) {
      if (result.data) {
        const filteredPods = result.data.filter(
          (obj) =>
            obj.trackerIdentNumber !== "BOA-NEW" &&
            obj.driverName !== "" &&
            obj.driverName !== null
        );
        filteredPods.map((item) => {
          item.id = item.deviceId;
          item.uniqueId = item.trackerIdentNumber;
          item.text = `${item.companyName} - ${item.driverName}`;
          item.value = item;
          item.content = <span style={{ color: "#66cc23" }}>{item.text}</span>;
        });
        return filteredPods;
      }
    }
    return false;
  });
};

export const apiSubmitJob = async (data, location) => {
  const payload = {
    companyAssetId: data.companyAssetId,
    uniqueId: data.uniqueId,
    deviceId: data.deviceId,
    locationLat: location.lat.toString(),
    locationLng: location.lng.toString(),
    customerLocation: data.customerLocation,
    customerPurchaseOrderNumber: data.customerPurchaseOrderNumber,
    urgencyId: data.urgencyId,
    jobDesc: data.jobDesc,
    assetPropValue: data.assetPropValue,
    assetPropUnit: data.assetPropUnit,
  };
  return axios
    .post(`/api/Jobbing/book-job?tz=${timeZone}`, payload)
    .then((result) => {
      if (result) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiGetServiceHistory = (id) => {
  const payload = {
    companyAssetId: id,
    timeZone: timeZone,
  };
  return axios
    .get(`/api/Assets/service-history`, { params: payload })
    .then((result) => {
      if (result && result.data) {
        const list = JSON.parse(result.data.result);

        // Create an object to group the data by day
        const groupedByDay = {};

        list.forEach((item) => {
          const date = new Date(item.CreatedDateTime);
          const formattedDay = formatDate(date);

          if (!groupedByDay[formattedDay]) {
            groupedByDay[formattedDay] = [];
          }

          groupedByDay[formattedDay].push(item);
        });

        return groupedByDay;
      }
      return false;
    });
};

function formatDate(date) {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export const apiGetDTLocationsList = (assetId) => {
  return axios
    .get(`/api/DigitalTwin/digital-twin-locations-list?assetId=${assetId}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          if (result.data) {
            return result.data;
          }
        }
      }
      return false;
    });
};

// Asset RS23

export const apiGetRS23Files = (assetId) => {
  return axios
    .get(`/api/Assets/asset-files-rs23?assetId=${assetId}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          if (result.data) {
            return result.data.response;
          }
        }
      }
      return false;
    });
};

export const apiUploadRS23File = async (file, id, categoryId) => {
  const formDataParam = new FormData();

  formDataParam.append("file", file);
  return axios
    .post(
      `/api/Assets/upload-asset-file-rs23?assetId=${id}&rs23categoryId=${categoryId}`,
      formDataParam,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((result) => {
      if (result && result.data) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      console.log(error);
      if (error.response.data) {
        console.log(error.response.data);
        const data = error.response.data;
        showErrorToast(data?.message);
        if (data.statusCode === 400) return false;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiDeleteRS23File = async (id) => {
  return axios
    .delete(`/api/Assets/delete-asset-file-rs23?fileId=${id}`)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};

// Asset Location RS23 APIs

export const apiGetAssetLocationRS23Files = (assetLocId) => {
  return axios
    .get(`/api/Assets/asset-location-files-rs23?assetLocId=${assetLocId}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          if (result.data) {
            return result.data.response;
          }
        }
      }
      return false;
    });
};

export const apiUploadAssetLocationRS23File = async (file, id, categoryId) => {
  const formDataParam = new FormData();

  formDataParam.append("file", file);
  return axios
    .post(
      `/api/Assets/upload-asset-location-file-rs23?assetLocId=${id}&rs23categoryId=${categoryId}`,
      formDataParam,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((result) => {
      if (result && result.data) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      console.log(error);
      if (error.response.data) {
        console.log(error.response.data);
        const data = error.response.data;
        showErrorToast(data?.message);
        if (data.statusCode === 400) return false;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiDeleteAssetLocationRS23File = async (id) => {
  return axios
    .delete(`/api/Assets/delete-asset-location-file-rs23?fileId=${id}`)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};
