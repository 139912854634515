// eslint-disable-next-line
const LOCAL_ENV = "local";
// eslint-disable-next-line
const UAT_ENV = "uat";
// eslint-disable-next-line
const PROD_ENV = "production";

// const env = LOCAL_ENV;
export const env = PROD_ENV;

const LOCAL = {
  API_PATH: "https://dev-boahub-api-nextgen.boahub.co.nz/",
  X_API_KEY:
    "CjP5ALkhQvXfDhk96Tq8rD384ausfKGLdYkR5HUUdW86rCj6ALe6gMWhpZHPWhbujn48Wx",
};

const UAT = {
  API_PATH: "https://uat-api.boahub.co/",
  X_API_KEY:
    "CjP5ALkhQvXfDhk96Tq8rD384ausfKGLdYkR5HUUdW86rCj6ALe6gMWhpZHPWhbujn48Wx",
};

const PROD = {
  API_PATH: "https://api.boahub.co/",
  X_API_KEY:
    "CjP5ALkhQvXfDhk96Tq8rD384ausfKGLdYkR5HUUdW86rCj6ALe6gMWhpZHPWhbujn48Wx",
};

export const envVariables = () => {
  const isProduction = PROD_ENV === env;
  const isUAT = UAT_ENV === env;

  if (isProduction) {
    console.debug("Production Env");
    return PROD;
  }

  if (isUAT) {
    console.debug("UAT Env");
    return UAT;
  }
  console.debug("Development Env");
  return LOCAL;
};
