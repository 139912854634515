import React, { useCallback, useEffect, useState } from "react";
import "./jobbing.css";
import {
  Card,
  Checkbox,
  Dimmer,
  Divider,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Loader,
  Button,
  Header,
  Select,
  TextArea,
  Modal,
  Popup,
  Message,
  Container,
} from "semantic-ui-react";
import MetaData from "../../components/MetaData";
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import rightArrow from "../../../src/assets/images/right-transparent.png";
import xeroIcon from "../../../src/assets/images/xero_icon.png";
import Autocomplete from "react-google-autocomplete";
import GoogleMapReact from "google-map-react";
import {
  apiAddtoExistingXeroInvoice,
  apiArchiveJob,
  apiAssetsSearch,
  apiCreateJob,
  apiCustomerList,
  apiDeleteJob,
  apiDiscardXeroInvoice,
  apiGeneratePDF,
  apiGenerateXeroInvoice,
  apiGenerateXeroInvoicePDF,
  apiGetHosePositions,
  apiGetJobTimeLogs,
  apiGetJobbing,
  apiGetJobbingList,
  apiGetUrgencies,
  apiJobTimeLogManual,
  apiJobTimeLogStart,
  apiJobTimeLogStop,
  apiRemoveJobImage,
  apiResendXeroInvoice,
  apiRestoreJob,
  apiSaveJobImage,
  apiSetTechnicianFilter,
  apiUpdateJob,
  apiXeroCustomerList,
  apiXeroInvoiceList,
} from "./jobbing.api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import { hoseMakerTempData, jobStatusList, tempData } from "./data";
import HoseMakerNew from "../HoseMakerNew";
import NumericInputBox from "../../components/NumericInputBox";
import productDefaultImage from "../../assets/images/product_default.png";
import noImageAvailable from "../../assets/images/no_image_available.png";
import SearchComponent from "../../containers/Search";
import AssetSearch from "./searchAsset";
import CustomerSearch from "./searchCustomer";
import ContactSearch from "./searchContact";
import { apiGetStockLocationList } from "../Settings/settings.api";
import { apiGetUsersListWithCurrent } from "../MyAssets/myAssets.api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  formatDateTime,
  padZero,
  sortKeysWithDatesFirst,
} from "../../utils/utils";
import JobImageUploader from "./ImageUploader";
import ImageViewer from "react-simple-image-viewer";
import RS23SetPriceForm from "./rs23setprice";

const Jobbing = (props) => {
  const [data, setData] = useState([]);
  const [jobListData, setJobListData] = useState([]);
  const [openHoseMaker, setOpenHoseMaker] = React.useState(false);
  const [openOtherComponents, setOpenOtherComponents] = React.useState(false);
  const [openCustomItem, setOpenCustomItem] = React.useState(false);
  const [openRemoveBOM, setOpenRemoveBOM] = React.useState(false);
  const [openToInvoiced, setOpenToInvoiced] = React.useState(false);
  const [openToInprogress, setOpenToInprogress] = React.useState(false);
  const [openSendJob, setOpenSendJob] = React.useState(false);
  const [hoseMakerData, setHoseMakerData] = useState(hoseMakerTempData);
  const [hoseMakerIndex, setHoseMakerIndex] = useState(null);
  const [assetType, setAssetType] = useState("");
  const [assetImage, setAssetImage] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedData, setSelectedData] = useState(tempData);
  const [formData, setFormData] = useState(tempData);
  const [urgenciesData, setUrgenciesData] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [assetLoading, setAssetLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [hasLocation, setHasLocation] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [assetSearch, setAssetSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState(6);
  const [technicianFilter, setTechnicianFilter] = useState("");
  const [technicianName, setTechnicianName] = useState("");
  const [technicianList, setTechnicianList] = useState([]);
  const [technicianFilterList, setTechnicianFilterList] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [customProductText, setcCustomProductText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState({
    lat: -42.312144,
    lng: 172.474153,
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [userCountryCode, setUserCountryCode] = useState("");
  const [countries, setCountries] = useState([]);

  //Customer
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [openIsDuplicateCustomer, setOpenIsDuplicateCustomer] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState("");
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    if (selectedCustomer) {
      handleChange("customer", selectedCustomer);
    }
  }, [selectedCustomer]);

  //Contact
  const [selectedContact, setSelectedContact] = useState({});

  useEffect(() => {
    if (selectedContact) {
      handleChange("customerContact", selectedContact);
    }
  }, [selectedContact]);

  //Stock Location
  const [openSelectStockLocation, setOpenSelectStockLocation] = useState(false);
  const [openStockLocationError, setOpenStockLocationError] = useState(false);
  const [stockLocationError, setStockLocationError] = useState({});
  const [stocklocationList, setStocklocationList] = useState([]);

  //Hose Tagging
  const [isAssemble, setIsAssemble] = useState(false);

  //Jobbing Image
  const [jobImages, setJobImages] = useState([]);
  const [jobAdditionalImages, setJobAdditionalImages] = useState([]);
  const [images, setImages] = useState([]);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [deleteFileOpen, setDeleteFileOpen] = useState(false);
  const [selectedFile, setSelectefFile] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isAdditionalViewerOpen, setIsAdditionalViewerOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [technicianDeleted, setTechnicianDeleted] = useState(false);

  //Xero
  const [xeroCustomerList, setXeroCustomerList] = useState([]);
  const [xeroInvoicesList, setXeroInvoicesList] = useState([]);
  const [xeroDraftInvoicesList, setXeroDraftInvoicesList] = useState([]);
  const [discardInvoiceOpen, setDiscardInvoiceOpen] = useState(false);
  const [invoice, setInvoice] = useState({
    invoiceStatus: 1,
    contactId: "",
    invoiceID: null,
  });

  //Automatic Time Recording
  const [seconds, setSeconds] = useState(0);
  const [running, setRunning] = useState(false);
  const [currentLog, setCurrentLog] = useState(null);
  const [timeLogs, setTimeLogs] = useState([]);
  const [openManualTimeEntry, setOpenManualTimeEntry] = useState(false);
  const [manualTimeEntry, setManualTimeEntry] = useState({
    id: 0,
    jobId: "",
    notes: null,
    timeSpanMinutes: null,
  });

  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else if (!running && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [running, seconds]);

  const handleTimeEntryChange = (e) => {
    const { name, value } = e.target;
    setManualTimeEntry((prevState) => ({
      ...prevState,
      [name]: name === "timeSpanMinutes" ? Number(value) : value,
    }));
  };

  const [selectedRS23Index, setSelectedRS23Index] = useState(0);
  const [openRS23Price, setOpenRS23Price] = useState(false);
  const [rs23Price, setRS23Price] = useState(0);
  const [rs23PriceTitle, setRS23PriceTitle] = useState("");

  const changeRS23priceHandler = (e, hoseMakerIndex, unitPrice) => {
    setOpenRS23Price(true);
    setRS23Price(unitPrice);
    setRS23PriceTitle(`SET RS23 PRICE FOR HOSE ITEM #${hoseMakerIndex + 1}`);
    setSelectedRS23Index(hoseMakerIndex);
    e.stopPropagation();
  };

  const closeRS23PriceForm = () => {
    setOpenRS23Price(false);
    onAutoSaveJob(formData);
  };

  // Function to format time as "HH:MM:SS"
  const formatTime = (totalSeconds) => {
    if (!totalSeconds) return "00:00:00";
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    const pad = (num) => String(num).padStart(2, "0");

    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  };

  // Function to toggle the running state
  const toggleTimer = () => {
    onStartStopJobTimeLog();
  };

  // Function to call API time clock start/stop
  const onStartStopJobTimeLog = async () => {
    let result = null;

    if (!running) {
      result = await apiJobTimeLogStart(selectedId);
    } else {
      result = await apiJobTimeLogStop(selectedId, currentLog.id);
      setSeconds(0);
    }

    if (result) {
      setCurrentLog(result);
      getJobbingTimeLogsData();
      onSearch(searchTerm, filterStatus, technicianFilter);
      setRunning(!running);
    } else {
      showErrorToast("Something went wrong");
    }
  };

  // Function to call API time clock start/stop
  const onSaveManualTimeEntry = async () => {
    try {
      setIsLoading(true);
      const jobData = await apiJobTimeLogManual(manualTimeEntry, selectedId);
      if (jobData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Manual Time Entry Saved Successfully
          </div>
        );
        setTimeout(function () {
          setIsLoading(false);
          setOpenManualTimeEntry(false);
          getJobbingTimeLogsData();
          setManualTimeEntry({
            id: 0,
            jobId: "",
            notes: null,
            timeSpanMinutes: null,
          });
        }, 300);
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const getStockLocationsData = async () => {
    const result = await apiGetStockLocationList();
    if (result) {
      setStocklocationList(result);
    } else {
      setStocklocationList([]);
    }
  };

  const mileage =
    localStorage.getItem("userCountryCode") === "US" ? "mi" : "km";

  // Unit options for hours/mileage
  const options = [
    { key: "hrs", text: "hrs", value: "hrs" },
    { key: mileage, text: mileage, value: mileage },
  ];

  const invoiceStatuses = [
    { text: "Save as draft", value: 1 },
    { text: "Complete and send invoice", value: 2 },
  ];

  const locationRef = useRef(null);
  const ref = useRef();
  const { events } = useDraggable(ref);

  function handleChange(dataType, value) {
    const userName = localStorage.getItem("user");
    let newState = [];
    newState.push(formData);
    let jobData = newState.map((item, i) => {
      if (i == 0) {
        if (dataType == "technicianEmail") {
          item.technicianSelected = true;
        }
        if (dataType == "dueDateTime") {
          var date = new Date(value);
          const formattedDate = date.toISOString();
          item.dueDateTime = formattedDate;
          setSelectedDate(value);
          return item;
        }
        if (dataType == "companyName") {
          item.customer = {};
          item.customer.companyName = value;
          item.customer.userName = userName;
          item.customer.id = null;
        }
        if (dataType == "contactName") {
          item.customerContact = {};
          item.customerContact.contactName = value;
          item.customerContact.userName = userName;
          item.customerContact.id = null;
          item.customerContact.customerId = item.customer?.id
            ? item.customer?.id
            : null;
        }
        if (dataType == "customer") {
          item.companyName = value.companyName;
        }
        if (dataType == "customerContact") {
          item.contactName = value.contactName;
          item.contactNumber = value.contactNumber;
          item.contactEmail = value.email;
        }
        if (dataType == "selectCustomer" && value == true) {
          if (!item.contactName) item.selectContact = true;
        }
        if (dataType == "makeId" && value == 0) {
          setAssetSearch("");
          setAssetType("");
          item.equipmentTypeId = null;
          item.assetId = null;
          setAssetImage("");
          setLocationList([]);
        }
        if (dataType == "makeId" && value != 0) {
          item.equipmentTypeId = null;
          item.assetId = null;
          item.otherAssetDetails = null;
          item.makeName = "";
          item.equipmentTypeName = "";
          item.model = "";
          setAssetType("");
          setAssetImage("");
          setLocationList([]);
        }
        if (dataType == "asset" && value) {
          item.assetId = value.id;
          item.otherAssetDetails = null;
          return item;
        }
        if (dataType == "customerLocation") {
          if (locationRef && locationRef.current)
            return {
              ...item,
              [dataType]: locationRef.current.refs.input.value,
            };
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });

    if (dataType === "assetId") {
      onAutoSaveJob(jobData[0]);
    }
    setFormData(jobData[0]);
  }

  function handleInvoiceChange(dataType, value) {
    let newState = [];
    newState.push(invoice);
    let invoiceData = newState.map((item, i) => {
      if (i == 0) {
        if (dataType === "contactId") {
          item.invoiceID = null;
          item.invoiceStatus = 1;
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setInvoice(invoiceData[0]);
  }

  const addOtherComponents = async (product) => {
    var currentDate = new Date();
    var utcDate = currentDate.toISOString();
    const productData = {
      productId: product.id,
      quantity: 1,
      stockCode: product.stockCode,
      stockDescription: product.stockDescription,
      image128: product.stockImage1,
      name: product.stockDescription,
      barcode: product.stockCode,
      createdDateTime: utcDate,
    };

    let newState = formData.inventoryComponents;
    const existing = newState.find(
      (element) => element.productId === product.id
    );

    if (!existing) {
      newState.push(productData);
    } else {
      let invComponentsNewState = newState.map((item, i) => {
        if (item.productId === product.id) {
          item.quantity += 1;
        }
        return item;
      });
      handleChange("inventoryComponents", invComponentsNewState);
      setOpenOtherComponents(false);
      return true;
    }

    // Sort inventory components AFTER adding product
    newState.sort(function (a, b) {
      return new Date(b.createdDateTime) - new Date(a.createdDateTime);
    });

    handleChange("inventoryComponents", newState);
    setOpenOtherComponents(false);
  };

  const addCustomComponents = async () => {
    const productData = {
      productId: null,
      stockCode: null,
      stockDescription: customProductText,
      image128: null,
      name: customProductText,
      barcode: null,
      description: customProductText,
      quantity: 1,
      sellPrice: 0,
    };

    let newState = formData.inventoryNonBOA;
    newState.push(productData);

    handleChange("inventoryNonBOA", newState);
    setOpenCustomItem(false);
  };

  function handleOtherComponentQuantityChange(index, value) {
    let newState = [];
    newState.push(formData);
    let jobData = newState.map((item, i) => {
      if (i == 0) {
        item.inventoryComponents[index].quantity = value;
      }
      if (value == 0) {
        item.inventoryComponents.splice(index, 1);
      }
      return item;
    });

    handleChange("inventoryComponents", jobData[0].inventoryComponents);
  }

  function handleCustomComponentQuantityChange(index, value) {
    let newState = [];
    newState.push(formData);
    let jobData = newState.map((item, i) => {
      if (i == 0) {
        item.inventoryNonBOA[index].quantity = value;
      }
      if (value === -1) {
        item.inventoryNonBOA.splice(index, 1);
      }
      return item;
    });

    handleChange("inventoryNonBOA", jobData[0].inventoryNonBOA);
  }

  function handleCustomComponentPriceChange(index, value) {
    let newState = [];
    newState.push(formData);
    let jobData = newState.map((item, i) => {
      if (i == 0) {
        item.inventoryNonBOA[index].sellPrice = value;
      }
      return item;
    });

    handleChange("inventoryNonBOA", jobData[0].inventoryNonBOA);
  }

  function handleBOMQuantityChange(index, value) {
    let newState = [];
    newState.push(formData);
    let jobData = newState.map((item, i) => {
      if (i == 0) {
        item.hoseMaker[index].quantity = value;
      }
      if (value == 0) {
        item.hoseMaker.splice(index, 1);
      }
      return item;
    });

    handleChange("hoseMaker", jobData[0].hoseMaker);
  }

  const addBOM = (data) => {
    let newState = [];
    newState.push(formData);
    let jobData = newState.map((item, i) => {
      if (i == 0) {
        item.hoseMaker.push(data);
        return item;
      }
      return item;
    });

    onAutoSaveJob(jobData[0]);
    setFormData(jobData[0]);
  };

  const updateBOM = (data, index) => {
    let newState = [];
    newState.push(formData);
    let jobData = newState.map((item, i) => {
      return item;
    });

    jobData[0].hoseMaker.map((item, i) => {
      if (index === i) {
        jobData[0].hoseMaker[i] = data;
      }
    });

    onAutoSaveJob(jobData[0]);
    setFormData(jobData[0]);
  };

  const onCancelHoseMaker = () => {
    setOpenHoseMaker(false);
  };

  const onSearch = async (query, status, technician) => {
    setSearchLoading(true);
    const result = await apiGetJobbingList(query, status, technician);
    if (result) {
      setSearchLoading(false);
      setData(result);
      setSearchLoading(false);
      const groupedByDateTime = result.reduce((result, item) => {
        var key = "Completed";
        if (item.jobStatusId < 3) {
          const date = item.dueDateTime
            ? new Date(item.dueDateTime)
            : "Unconfirmed"; // Use ISO string to ensure consistent keys

          var today = new Date();
          today.setHours(0, 0, 0, 0);
          var tomorrow = new Date(today);
          tomorrow.setDate(today.getDate() + 1);

          if (date !== "Unconfirmed") {
            if (date.toDateString() === today.toDateString()) {
              key = "Today";
            } else if (date.toDateString() === tomorrow.toDateString()) {
              key = "Tomorrow";
            } else if (date < today) {
              key = "Overdue";
            } else {
              key = date.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
              });
            }
          } else {
            key = "Unconfirmed";
          }
        }
        // Check if the key already exists, if not, create an empty array
        result[key] = result[key] || [];
        result[key].push(item);

        return result;
      }, {});
      const orderedKeys = Object.keys(groupedByDateTime).sort((a, b) => {
        // Define the order of keys
        const order = {
          Overdue: -3,
          Today: -2,
          Tomorrow: 1,
          Unconfirmed: 100,
          Completed: 101,
        };

        // Compare keys based on the order defined
        return (
          (order[a] || Number.MAX_SAFE_INTEGER) -
          (order[b] || Number.MAX_SAFE_INTEGER)
        );
      });

      const finalOrderedKeys = orderedKeys.sort((a, b) => {
        if (a === "Unconfirmed") return 1;
        if (b === "Unconfirmed") return -1;
        return 0;
      });

      const finalOrderedKeys2 = finalOrderedKeys.sort((a, b) => {
        if (a === "Completed") return 1;
        if (b === "Completed") return -1;
        return 0;
      });

      const sortedKeys = sortKeysWithDatesFirst(finalOrderedKeys2);

      const orderedGrouped = sortedKeys.reduce((result, key) => {
        result[key] = groupedByDateTime[key];
        return result;
      }, {});

      // Keys to exclude from sorting
      const excludeKeys = ["Completed", "Unconfirmed"];

      // Iterate over the keys of the object
      for (const key in orderedGrouped) {
        // Check if the key is not in the excludeKeys array
        if (!excludeKeys.includes(key)) {
          // Sort the array based on dueDateTime
          orderedGrouped[key].sort((a, b) => {
            if (a.dueDateTime === null) return 1; // Null dueDateTime goes to the end
            if (b.dueDateTime === null) return -1; // Null dueDateTime goes to the end
            return new Date(a.dueDateTime) - new Date(b.dueDateTime);
          });
        }
      }

      setJobListData(orderedGrouped);
    }
  };

  const onSubmitJob = async (status, progress) => {
    if (formData.id === "") {
      onSubmitCreateJob(status, progress);
    } else {
      onSubmitUpdateJob(status, progress);
    }
  };

  const onSubmitCreateJob = async (status, progress) => {
    if (formData.makeId === 0) {
      if (
        formData.makeName === "" ||
        formData.equipmentTypeName === "" ||
        formData.model === ""
      ) {
        showErrorToast("Please complete all required fields");
        if (progress) handleChange("jobStatusId", status - 1);
        return false;
      } else {
        formData.otherAssetDetails = `${formData.makeName} | ${formData.equipmentTypeName} | ${formData.model}`;
      }
    }
    try {
      const jobData = await apiCreateJob(formData, location, status);
      if (jobData) {
        getJobbingListData();
        showSuccessToast(
          <div>
            <i className="check icon" /> Job Created Successfully
          </div>
        );
        setSelectedId(jobData.id);
        setSelectedCard(jobData?.jobDetails?.jobNumber);
      } else {
        if (progress) handleChange("jobStatusId", status - 1);
      }
    } catch {
      showErrorToast("Please complete all required fields");
      if (progress) handleChange("jobStatusId", status - 1);
    }
  };

  const onSubmitUpdateJob = async (status, progress) => {
    setIsLoading(true);
    if (status > 2 && formData.makeId === 0) {
      if (
        formData.makeName === "" ||
        formData.equipmentTypeName === "" ||
        formData.model === ""
      ) {
        showErrorToast("Please complete all required fields");
        if (progress) handleChange("jobStatusId", status - 1);
        setIsLoading(false);
        return false;
      } else {
        formData.otherAssetDetails = `${formData.makeName} | ${formData.equipmentTypeName} | ${formData.model}`;
      }
    }
    if (formData.makeId === 0) {
      formData.otherAssetDetails = `${formData.makeName} | ${formData.equipmentTypeName} | ${formData.model}`;
    }
    const jobData = await apiUpdateJob(formData, location, status);
    if (jobData) {
      if (jobData.errors) {
        setStockLocationError(jobData);
        setOpenStockLocationError(true);
        setIsLoading(false);
        if (progress) handleChange("jobStatusId", status - 1);
      }
      if (jobData.statusCode === 403) {
        setOpenSelectStockLocation(true);
        setIsLoading(false);
        return false;
      }

      setIsLoading(false);
      setOpenSelectStockLocation(false);
      setOpenToInprogress(false);
      setOpenToInvoiced(false);
      getJobbingListData();
      showSuccessToast(
        <div>
          <i className="check icon" /> Job Updated Successfully
        </div>
      );
      if (jobData.duplicateCustomerMessage) {
        setDuplicateMessage(jobData.duplicateCustomerMessage);
        setOpenIsDuplicateCustomer(true);
      }
      setTimeout(function () {
        getJobbingData();
      }, 300);
    } else {
      setIsLoading(false);
      showErrorToast("Please complete all required fields");
      if (progress) handleChange("jobStatusId", status - 1);
    }
  };

  const onAutoSaveJob = async (data) => {
    if (formData.makeId === 0) {
      if (
        formData.makeName === "" ||
        formData.equipmentTypeName === "" ||
        formData.model === ""
      ) {
      } else {
        formData.otherAssetDetails = `${formData.makeName} | ${formData.equipmentTypeName} | ${formData.model}`;
      }
    }
    try {
      const jobData = await apiUpdateJob(data, location, data.jobStatusId);
      if (jobData) {
        setOpenToInprogress(false);
        setOpenToInvoiced(false);
        showSuccessToast(
          <div>
            <i className="check icon" /> Changes Saved Successfully
          </div>
        );
        setTimeout(function () {
          getJobbingData();
        }, 300);
      } else {
        // showErrorToast("Changes not saved");
      }
    } catch {
      // showErrorToast("Changes not saved");
    }
  };

  const onGeneratePDF = async (type) => {
    setIsLoading(true);
    try {
      const pdfData = await apiGeneratePDF(
        formData.id,
        selectedData?.jobDetails?.jobNumber,
        type
      );
      if (pdfData) {
        setIsLoading(false);
        setOpenSendJob(false);
        showSuccessToast(
          <div>
            <i className="check icon" /> PDF Generated Successfully
          </div>
        );
      } else {
        setIsLoading(false);
        showErrorToast("Generate PDF Failed");
      }
    } catch {
      setIsLoading(false);
      showErrorToast("Generate PDF Failed");
    }
  };

  const onArchiveJob = async () => {
    const jobData = await apiArchiveJob(formData.id);
    if (jobData) {
      getJobbingData();
      getJobbingListData();
      showSuccessToast(
        <div>
          <i className="check icon" /> Job Archived Successfully
        </div>
      );
    } else {
      showErrorToast("Something went wrong");
    }
  };

  const onRestoreJob = async () => {
    const jobData = await apiRestoreJob(formData.id);
    if (jobData) {
      getJobbingData();
      getJobbingListData();
      showSuccessToast(
        <div>
          <i className="check icon" /> Job Restored Successfully
        </div>
      );
    } else {
      showErrorToast("Something went wrong");
    }
  };

  const onDeleteJob = async () => {
    const jobData = await apiDeleteJob(formData.id);
    if (jobData) {
      getJobbingListData();
      onNewJob();
      showSuccessToast(
        <div>
          <i className="check icon" /> Job Deleted Successfully
        </div>
      );
    } else {
      showErrorToast("Something went wrong");
    }
  };

  const onNewJob = () => {
    const newData = {
      id: "",
      jobNumber: "",
      companyName: "",
      contactName: "",
      contactNumber: "",
      contactEmail: "",
      customerPurchaseOrderNumber: "",
      customerLocation: "",
      locationLat: null,
      locationLng: null,
      urgencyId: null,
      assetTextId: "",
      assetSerialNumber: "",
      makeId: null,
      equipmentTypeId: null,
      assetId: null,
      otherAssetDetails: null,
      assetPropValue: "",
      assetPropUnit: "hrs",
      jobDesc: "",
      jobImageDetails: null,
      jobStatusId: 1,
      distanceTravelled: null,
      hoursLabour: null,
      hoseMaker: [],
      makeName: "",
      equipmentTypeName: "",
      model: "",
      saveContactDetails: true,
      customer: {},
      customerContact: {},
      selectCustomer: true,
      selectContact: true,
      dueDateTime: null,
    };
    setSelectedDate(null);
    setSelectedId("");
    setSelectedData(newData);
    setFormData(newData);
    setSelectedCard("");
    setHasLocation(false);
    setAssetSearch("");
    setAssetType("");
    setAssetImage("");
    if (locationRef && locationRef.current)
      locationRef.current.refs.input.value = "";
    const geolocation = {
      lat: -42.312144,
      lng: 172.474153,
    };
    setLocation(geolocation);
    setHasLocation(false);
  };

  const onAddressSelection = (place) => {
    handleChange("customerLocation", locationRef.current.refs.input.value);
    if (place && place.geometry) {
      const geolocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setLocation(geolocation);
      setHasLocation(true);
    }
  };

  const openGoogleMaps = () => {
    if (formData?.customerLocation === "") {
      showErrorToast("Enter location first");
      return false;
    }
    const googleMapsURL = `https://www.google.com/maps?q=${formData?.customerLocation}`;

    window.open(googleMapsURL, "_blank");
  };

  const getJobbingListData = async () => {
    // const result = await apiGetJobbingList(
    //   searchTerm,
    //   filterStatus,
    //   technicianFilter
    // );
    // if (result) {
    //   setData(result);
    // }
    onSearch(searchTerm, filterStatus, technicianFilter);
  };

  const getJobbingData = async () => {
    setDataLoading(true);
    const result = await apiGetJobbing(selectedId);
    if (result) {
      setSelectedData(result);
      setDataLoading(false);
    } else {
      showErrorToast("Failed to load the job card, please try again");
      setDataLoading(false);
    }
  };

  const getJobbingTimeLogsData = async () => {
    const result = await apiGetJobTimeLogs(selectedId);
    if (result) {
      setSeconds(0);
      let runningLogs = [];
      runningLogs = result.jobTimeLogs?.filter(
        (obj) => obj.updatedByDateTime === null
      );
      if (runningLogs?.length > 0) {
        runningLogs.map((log) => {
          if (log.createdBy === localStorage.getItem("user")) {
            setCurrentLog(log);
            const startTime = new Date(log.createdDateTime);

            // Get the current time
            const currentTime = new Date();

            // Calculate the difference in milliseconds
            const timeDifferenceMillis =
              currentTime.getTime() - startTime.getTime();

            // Convert milliseconds to seconds
            const timeDifferenceSeconds = Math.floor(
              timeDifferenceMillis / 1000
            );

            setRunning(true);
            if (timeDifferenceSeconds > 0) {
              setSeconds(timeDifferenceSeconds);
            } else {
              setSeconds(0);
            }
          }
        });
      }

      handleChange("hoursLabour", result.jobLabour?.hoursLabour);
      setTimeLogs(result.jobTimeLogs);
    } else {
      showErrorToast("Something went wrong");
    }
  };

  const getImagesData = async () => {
    setDataLoading(true);
    const result = await apiGetJobbing(selectedId);
    if (result) {
      setJobImages(result.jobAssetImages);
      setJobAdditionalImages(result.jobAdditionalImages);
      let imagesFromData = [];
      let additionalImagesFromData = [];
      result.jobAssetImages?.map((item, i) => {
        imagesFromData.push(item.filePath);
      });
      result.jobAdditionalImages?.map((item, i) => {
        additionalImagesFromData.push(item.filePath);
      });
      setImages(imagesFromData);
      setAdditionalImages(additionalImagesFromData);
      setDataLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setDataLoading(false);
    }
  };

  const getDropdownData = async () => {
    const urgencies = await apiGetUrgencies();
    if (urgencies) {
      setUrgenciesData(urgencies);
    }
  };

  const getCustomersData = async () => {
    const customers = await apiCustomerList();
    if (customers) {
      setCustomerList(customers);
    }
  };

  const getUsersList = async () => {
    const result = await apiGetUsersListWithCurrent();
    if (result) {
      const list = result.filter(
        (item, index) => result.indexOf(item) === index
      );
      const filterList = result.filter(
        (item, index) => result.indexOf(item) === index
      );
      setTechnicianList(list);
      filterList.unshift({ text: "Unassigned", value: "Unassigned" });
      filterList.unshift({ text: "All", value: "All" });
      setTechnicianFilterList(filterList);
    }
  };

  const getHosePositions = async () => {
    const hosePositions = await apiGetHosePositions(assetType);
    if (hosePositions) {
      setAssetImage(hosePositions.url);
      setLocationList(hosePositions.positions);
    } else {
      setAssetImage("");
      setLocationList([]);
    }
  };

  const getPositionName = (id) => {
    if (locationList.length > 0) {
      let position = {};
      position = locationList.find((location) => location.id === id);
      if (position) return position.name ? position.name : "";
    }
    return "NO LOCATION ASSIGNED";
  };

  const getPositionImage = (id) => {
    if (locationList.length > 0) {
      let position = {};
      position = locationList.find((location) => location.id === id);
      if (position) return position.url ? position.url : noImageAvailable;
    }
    return noImageAvailable;
  };

  const getXeroCustomersData = async () => {
    const customers = await apiXeroCustomerList();
    if (customers) {
      setXeroCustomerList(customers);
    }
  };

  const getXeroInvoicesData = async (contactId) => {
    const invoices = await apiXeroInvoiceList(contactId);
    if (invoices) {
      let filteredData = invoices.filter((inv) => inv.status === "DRAFT");
      setXeroDraftInvoicesList(filteredData);
      setXeroInvoicesList(invoices);
    }
  };

  const handleResultSelect = (e, { result }) => {
    setAssetLoading(true);
    const fullDescription = result.displayName.split(" | ");
    formData.makeName = fullDescription[0];
    formData.equipmentTypeName = fullDescription[1];
    formData.model = fullDescription[2];
    setAssetType(fullDescription[1]);
    handleChange("asset", result);
    setTimeout(function () {
      setAssetLoading(false);
    }, 500);
  };

  const handleAssetSearch = async (e, { value }) => {
    setAssetSearch(value);
  };

  const handleOpenAssetDetails = () => {
    setClicked(!clicked);
  };

  const handleGenerateXeroPDF = async (type) => {
    setIsLoading(true);
    try {
      const pdfData = await apiGenerateXeroInvoicePDF(invoice.invoiceID);
      if (pdfData) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        showErrorToast("Generate PDF Failed");
      }
    } catch {
      setIsLoading(false);
      showErrorToast("Generate PDF Failed");
    }
  };

  const handleDiscardXeroInvoice = async (type) => {
    setIsLoading(true);
    try {
      const invoiceData = await apiDiscardXeroInvoice(
        formData?.id,
        invoice.invoiceID
      );
      if (invoiceData) {
        setIsLoading(false);
        showSuccessToast(
          <div>
            <i className="check icon" /> Invoice Discarded Successfully
          </div>
        );
        setTimeout(function () {
          getJobbingData();
          getJobbingListData();
        }, 300);
      } else {
        setIsLoading(false);
        showErrorToast("Something went wrong");
      }
    } catch {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const handleGenerateInvoice = () => {
    if (invoice.invoiceID === "") {
      showErrorToast("Please select Invoice");
      return false;
    }
    if (invoice.invoiceID === null) {
      onSubmitCreateInvoice();
    } else {
      onAddToExistingInvoice();
    }
  };

  const onSubmitCreateInvoice = async () => {
    setIsLoading(true);
    try {
      const invoiceData = await apiGenerateXeroInvoice(
        formData?.id,
        invoice?.contactId,
        invoice?.invoiceStatus === 1 ? false : true
      );
      if (invoiceData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Invoice Created Successfully
          </div>
        );
        setIsLoading(false);
        setTimeout(function () {
          getJobbingData();
          getJobbingListData();
        }, 300);
      } else {
        setIsLoading(false);
        showErrorToast("Something went wrong");
      }
    } catch {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const onAddToExistingInvoice = async () => {
    setIsLoading(true);
    const invoiceData = await apiAddtoExistingXeroInvoice(
      formData?.id,
      invoice?.invoiceID,
      invoice?.invoiceStatus === 1 ? false : true
    );
    if (invoiceData) {
      setIsLoading(false);
      showSuccessToast(
        <div>
          <i className="check icon" />{" "}
          {invoice?.invoiceStatus === 1
            ? "Invoice Created Successfully"
            : "Invoice Generated"}
          Successfully
        </div>
      );
      setTimeout(function () {
        getJobbingData();
        getJobbingListData();
      }, 300);
    } else {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const onResendInvoice = async () => {
    setIsLoading(true);
    const invoiceData = await apiResendXeroInvoice(invoice?.invoiceID);
    if (invoiceData) {
      setIsLoading(false);
      showSuccessToast(
        <div>
          <i className="check icon" /> Invoice Sent Successfully
        </div>
      );
      getXeroInvoicesData(invoice?.contactId);
      setTimeout(function () {
        getJobbingData();
        getJobbingListData();
      }, 300);
    } else {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const getAssetList = async (search) => {
    setAssetLoading(true);
    setAssetList([]);
    if (search === "") {
      setAssetList([]);
      setAssetLoading(false);
      return false;
    }
    const assetsData = await apiAssetsSearch(search);
    if (assetsData) {
      setAssetList(assetsData);
      setAssetLoading(false);
    } else {
      setAssetLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAssetList(assetSearch);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [assetSearch]);

  useEffect(() => {
    //Get User's country data and country list
    const countryCode = localStorage.getItem("userCountryCode");
    setUserCountryCode(countryCode);
    const countryList = JSON.parse(localStorage.getItem("countries"));
    setCountries(countryList);

    const hasJobbing = localStorage.getItem("accessJobbing");
    if (hasJobbing !== "true") {
      props.history.push("/");
    }
    const defaultTechnicianFilter = localStorage.getItem(
      "defaultTechnicianFilter"
    );
    setTechnicianFilter(defaultTechnicianFilter);

    const delayDebounceFn = setTimeout(() => {
      getDropdownData();
      getStockLocationsData();
      getCustomersData();
      getUsersList();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    setRunning(false);
    setSeconds(0);
    if (selectedId != "") {
      getJobbingData();
    }
  }, [selectedId]);

  useEffect(() => {
    if (locationRef && locationRef.current)
      locationRef.current.refs.input.value = "";
    if (selectedData && selectedData.id != "") {
      setAssetSearch("");
      setCurrentStatus(selectedData.jobDetails.jobStatusId);
      setAssetType(
        selectedData.assetDetails.equipmentTypeName
          ? selectedData.assetDetails.equipmentTypeName
          : ""
      );
      if (
        selectedData.assetDetails?.otherAssetDetails !== "" &&
        selectedData.assetDetails?.otherAssetDetails !== null
      ) {
        const fullDescription =
          selectedData.assetDetails.otherAssetDetails.split(" | ");
        selectedData.makeName = fullDescription[0] ? fullDescription[0] : "";
        selectedData.equipmentTypeName = fullDescription[1]
          ? fullDescription[1]
          : "";
        selectedData.model = fullDescription[2] ? fullDescription[2] : "";
      } else {
        selectedData.makeName = selectedData.assetDetails?.makeName;
        selectedData.equipmentTypeName =
          selectedData.assetDetails?.equipmentTypeName;
        selectedData.model = selectedData.assetDetails?.model;
      }
      if (selectedData.customer?.id) {
        setSelectedCustomer(selectedData.customer);
      }
      // Xero Invoice
      if (
        selectedData.jobDetails?.jobStatusId > 2 &&
        selectedData.jobDetails?.invoiceId
      ) {
        setInvoice({
          invoiceStatus: 1,
          contactId: selectedData.jobDetails?.xeroContactId,
          invoiceID: selectedData.jobDetails?.invoiceId,
        });
      } else {
        setInvoice({
          invoiceStatus: 1,
          contactId: "",
          invoiceID: null,
        });
      }

      let selectedFormData = {
        id: selectedData.id,
        jobNumber: selectedData.jobDetails.jobNumber,
        companyName: selectedData.customerDetails.companyName,
        contactName: selectedData.customerContact
          ? selectedData.customerContact?.contactName
          : selectedData.customerDetails.contactName,
        contactNumber: selectedData.customerDetails.contactNumber,
        contactEmail: selectedData.customerDetails.contactEmail,
        customerPurchaseOrderNumber:
          selectedData.customerDetails.customerPurchaseOrderNumber,
        customerLocation: selectedData.customerDetails.customerLocation
          .split(", ")
          .filter((item) => item !== "")
          .join(", "),
        locationLat: selectedData.customerDetails.locationLat,
        locationLng: selectedData.customerDetails.locationLng,
        urgencyId: selectedData.jobDetails.urgencyId,
        assetTextId: selectedData.assetDetails.assetTextId,
        assetSerialNumber: selectedData.assetDetails.assetSerialNumber,
        makeId: selectedData.assetDetails.makeId
          ? selectedData.assetDetails.makeId
          : selectedData.assetDetails.otherAssetDetails
          ? 0
          : null,
        equipmentTypeId: selectedData.assetDetails.equipmentTypeId,
        assetId: selectedData.assetDetails.assetId,
        otherAssetDetails: selectedData.assetDetails.otherAssetDetails,
        assetPropValue: selectedData.assetDetails.assetPropValue,
        assetPropUnit: selectedData.assetDetails.assetPropUnit
          ? selectedData.assetDetails.assetPropUnit
          : "hrs",
        jobDesc: selectedData.jobDetails.jobDesc,
        jobImageDetails: selectedData.jobDetails.jobImageDetails,
        jobStatusId: selectedData.jobDetails.jobStatusId,
        distanceTravelled:
          selectedData.jobDetails.distanceTravelled != null
            ? selectedData.jobDetails.distanceTravelled
            : "",
        hoursLabour:
          selectedData.jobDetails.hoursLabour != null
            ? selectedData.jobDetails.hoursLabour
            : "",
        hoseMaker: selectedData.hoseMaker ? selectedData.hoseMaker : [],
        inventoryComponents: selectedData.inventoryComponents,
        inventoryNonBOA: selectedData.inventoryNonBOA,
        notes: selectedData.jobDetails.notes,
        makeName: selectedData.assetDetails.makeName
          ? selectedData.assetDetails.makeName
          : "",
        equipmentTypeName: selectedData.assetDetails.equipmentTypeName
          ? selectedData.assetDetails.equipmentTypeName
          : "",
        model: selectedData.assetDetails.model
          ? selectedData.assetDetails.model
          : "",
        isConflict: selectedData.jobDetails.jobNumber.includes("COPY")
          ? true
          : false,
        saveContactDetails: selectedData.saveContactDetails,
        customer: selectedData.customer?.companyName
          ? selectedData.customer
          : {},
        customerContact: selectedData.customerContact?.contactName
          ? selectedData.customerContact
          : {},
        makeName: selectedData.makeName ? selectedData.makeName : null,
        equipmentTypeName: selectedData.equipmentTypeName
          ? selectedData.equipmentTypeName
          : null,
        model: selectedData.model ? selectedData.model : null,
        selectCustomer: selectedData.customerId ? true : false,
        selectContact: selectedData.customerContactId ? true : false,
        technician: selectedData.technician,
        technicianEmail: selectedData.technicianEmail,
        dueDateTime: selectedData.jobDetails.dueDateTime,
        jobAdditionalImages: selectedData.jobAdditionalImages,
        jobAssetImages: selectedData.jobAssetImages,
      };
      if (selectedData.technicianEmail) {
        //find technician if exist
        let technician = technicianList.find(
          (item) => item.email === selectedData.technicianEmail
        );
        if (!technician) {
          setTechnicianDeleted(true);
        }
      }
      setSelectedDate(
        selectedData.jobDetails.dueDateTime
          ? new Date(selectedData.jobDetails.dueDateTime)
          : null
      );
      setJobImages(selectedData.jobAssetImages);
      setJobAdditionalImages(selectedData.jobAdditionalImages);
      let imagesFromData = [];
      let additionalImagesFromData = [];
      selectedData.jobAssetImages?.map((item, i) => {
        imagesFromData.push(item.filePath);
      });
      selectedData.jobAdditionalImages?.map((item, i) => {
        additionalImagesFromData.push(item.filePath);
      });
      selectedFormData.hoseMaker?.map((hose) => {
        hose.hoseIdTagging.map((hoseTag) => {
          hose.hoseName = hoseTag.hoseName;
          hose.hoseOemNumber = hoseTag.hoseOemNumber;
          hose.isHoseCleaned = hoseTag.isHoseCleaned;
          hose.isVisuallyInspected = hoseTag.isVisuallyInspected;
          hose.isDesignStandard = hoseTag.isDesignStandard;
        });
      });
      setImages(imagesFromData);
      setAdditionalImages(additionalImagesFromData);
      setFormData(selectedFormData);
    }
  }, [selectedData]);

  useEffect(() => {
    if (selectedData && selectedData.customerDetails) {
      if (
        selectedData.customerDetails?.locationLat !== "" &&
        selectedData.customerDetails?.locationLng !== "" &&
        selectedData.customerDetails?.locationLat !== "string" &&
        selectedData.customerDetails?.locationLng !== "string"
      ) {
        const geolocation = {
          lat: Number(selectedData.customerDetails?.locationLat),
          lng: Number(selectedData.customerDetails?.locationLng),
        };
        if (
          geolocation.lat !== location.lat &&
          geolocation.lng != location.lng
        ) {
          setLocation(geolocation);
          setHasLocation(true);
        }
      }
    }
  }, [selectedData, hasLocation]);

  useEffect(() => {
    if (formData && formData?.makeId && formData?.equipmentTypeId) {
    } else {
      setAssetImage("");
    }
  }, [formData?.makeId, formData?.equipmentTypeId]);

  useEffect(() => {
    if (formData && formData?.id) {
      const delayDebounceFn = setTimeout(() => {
        getJobbingTimeLogsData();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [formData?.id]);

  useEffect(() => {
    const defaultTechnicianFilter = localStorage.getItem(
      "defaultTechnicianFilter"
    );
    if (defaultTechnicianFilter !== technicianFilter) {
      if (technicianFilter === "" || technicianFilter === "All") {
        if (technicianFilter === "All") {
          onSetTechnicianFilter(technicianFilter);
          setTechnicianName("All");
        }
      } else {
        onSetTechnicianFilter(technicianFilter);
        let item = technicianList.find(
          (item) => item.value === technicianFilter
        );
        setTechnicianName(item ? item.text : "Unassigned");
      }
    } else {
      if (filterStatus >= 0) {
        const delayDebounceFn = setTimeout(
          () => {
            onSearch(searchTerm, filterStatus, technicianFilter);
          },
          searchTerm === "" ? 100 : 2000
        );

        return () => clearTimeout(delayDebounceFn);
      }
    }
  }, [searchTerm, filterStatus, technicianFilter]);

  const onSetTechnicianFilter = async (technician) => {
    setIsLoading(true);
    try {
      const result = await apiSetTechnicianFilter(technician);
      if (result) {
        window.localStorage.setItem("defaultTechnicianFilter", technician);
        setIsLoading(false);
        onSearch(searchTerm, filterStatus, technicianFilter);
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch (e) {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (assetType) {
      if (formData?.makeId !== 0) {
        getHosePositions();
      }
    }
  }, [assetType]);

  useEffect(() => {
    if (formData && formData?.jobStatusId > 2) {
      getXeroCustomersData();
    } else {
      setInvoice({
        invoiceStatus: 1,
        contactId: "",
        invoiceID: null,
      });
    }
  }, [formData?.jobStatusId]);

  useEffect(() => {
    if (invoice && invoice?.contactId !== "") {
      getXeroInvoicesData(invoice?.contactId);
    }
  }, [invoice?.contactId, formData?.jobStatusId]);

  const handleFileChange = async (file) => {
    if (file) {
      if (file.size >= 25000000) {
        showErrorToast("File size cannot exceed 25MB.");
        setIsLoading(false);
        setFilesLoading(false);
        setImageLoading(false);
      }
      setIsLoading(true);
      setImageLoading(true);
      try {
        const result = await apiSaveJobImage(file, formData.id);
        if (result) {
          getImagesData();
          setIsLoading(false);
          setImageLoading(false);
          showSuccessToast(
            <div>
              <i className="check icon" /> Image Uploaded Successfully
            </div>
          );
        } else {
          setImageLoading(false);
          setIsLoading(false);
        }
      } catch {
        setImageLoading(false);
        setIsLoading(false);
      }
    } else {
    }
  };

  const onDeleteFile = async () => {
    setIsLoading(true);
    const assetData = await apiRemoveJobImage(selectedFile.id, formData.id);
    if (assetData) {
      showSuccessToast(
        <div>
          <i className="check icon" /> Image Deleted Successfully
        </div>
      );
      setDeleteFileOpen(false);
      getImagesData();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const openAdditionalImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsAdditionalViewerOpen(true);
  }, []);

  const closeAdditionalImageViewer = () => {
    setCurrentImage(0);
    setIsAdditionalViewerOpen(false);
  };

  const jobListGrouped = Object.entries(jobListData).map(
    ([category, list], index) => (
      <div style={{ marginTop: index === 0 ? 0 : 15 }}>
        <span style={{ fontSize: 13, marginLeft: 0, fontWeight: "bolder" }}>
          {category}
        </span>
        {list.map((data, i) => {
          var date = "";
          if (data.dueDateTime !== null) {
            var dateObject = new Date(data.dueDateTime);

            // Format the date
            var formattedDate = `${padZero(dateObject.getDate())}/${padZero(
              dateObject.getMonth() + 1
            )}/${dateObject.getFullYear().toString().slice(2)}`;
            var hours = dateObject.getHours() % 12 || 12; // Convert to 12-hour format
            var formattedTime = `${padZero(hours)}:${padZero(
              dateObject.getMinutes()
            )} ${dateObject.getHours() < 12 ? "AM" : "PM"}`;
            // Combine date and time
            date =
              data.jobStatusId < 3 && category !== "Overdue"
                ? `${formattedTime}`
                : `${formattedDate} ${formattedTime}`;
          }
          return (
            <Card
              className={
                selectedCard === data.jobNumber ? "job-card active" : "job-card"
              }
            >
              <Card.Content
                onClick={() => {
                  if (selectedCard !== data.jobNumber) {
                    setSelectedId(data.id);
                    setSelectedCard(data.jobNumber);
                  } else {
                    setHasLocation(false);
                    setSelectedId("");
                    setSelectedCard("");
                    setSelectedData(null);
                    setFormData(null);
                    setAssetType("");
                    setAssetImage("");
                    setAssetSearch("");
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <Grid columns={2} className="job-management">
                  <Grid.Row>
                    <Grid.Column
                      width={14}
                      style={{ paddingTop: 6, paddingBottom: 6 }}
                    >
                      <b>
                        <Card.Description>
                          Job #{data.jobNumber}
                        </Card.Description>
                      </b>
                      <Card.Description>
                        Company: {data.companyName}
                      </Card.Description>
                      <Card.Description>
                        Contact: {data.contactName}
                      </Card.Description>
                      {/* <Card.Description>
                                Location: Sample Location
                              </Card.Description> */}
                      <Card.Description>
                        Job Status: {data.jobStatusDesc}
                      </Card.Description>
                      {localStorage.getItem("isXeroConnected") === "true" &&
                        data.xeroInvoiceStatus && (
                          <Card.Description
                            style={{
                              color: "#13b5ea",
                            }}
                          >
                            Xero Invoice Status:{" "}
                            {data.xeroInvoiceStatus === "AUTHORISED"
                              ? "COMPLETED"
                              : data.xeroInvoiceStatus}
                          </Card.Description>
                        )}
                      <div
                        style={{
                          textAlign: "right",
                          fontSize: 13,
                          marginTop: 5,
                        }}
                      >
                        <span>{date}</span>
                      </div>
                      {data.runningTime?.length > 0 && data.jobStatusId < 3 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              fontSize: 13,
                            }}
                          >
                            <div
                              style={{
                                width: 10,
                                height: 10,
                                borderRadius: 30,
                                backgroundColor: "red",
                                marginRight: 7,
                                marginTop: 3,
                              }}
                            ></div>
                            <span style={{ fontStyle: "italic" }}>
                              In progress
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column
                      width={2}
                      style={{
                        padding: 0,
                        backgroundColor:
                          data.jobStatusId === 1
                            ? "#ff8f8f"
                            : data.jobStatusId === 2
                            ? "#ffdda6"
                            : data.jobStatusId === 4
                            ? "#71a8d2"
                            : data.jobStatusId === 0 || data.jobStatusId === 5
                            ? "#ced4db"
                            : "#c4f8c8",
                      }}
                    >
                      <div className="job-state">
                        <p className="job-state-text">
                          {data.jobStatusId === 4
                            ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                              data.jobStatusDesc
                            : data.jobStatusId === 3
                            ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                              data.jobStatusDesc
                            : data.jobStatusId === 0
                            ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                              data.jobStatusDesc
                            : data.jobStatusId === 5
                            ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                              data.jobStatusDesc
                            : data.jobStatusDesc}
                        </p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          );
        })}
      </div>
    )
  );

  return (
    <React.Fragment>
      <MetaData title="Job Management" />
      <Modal size="mini" open={openStockLocationError}>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <div style={{ textAlign: "left", marginBottom: 10 }}>
            <p style={{ color: "red" }}>Error</p>
          </div>
          <div
            style={{
              textAlign: "left",
              marginBottom: 10,
              fontWeight: "bold",
            }}
          >
            <p>{stockLocationError.message}</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => setOpenStockLocationError(false)}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={openSelectStockLocation}>
        {isLoading ? (
          <Dimmer active inverted>
            <Loader size="large"></Loader>
          </Dimmer>
        ) : null}
        <Modal.Header>Choose stock location</Modal.Header>
        <Modal.Content>
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            <p>
              Please select the stock <br /> location for this job card
            </p>
          </div>
          <Form size="mini">
            <Form.Field>
              <Dropdown
                search
                placeholder="Select Stock Location"
                selection
                options={stocklocationList}
                value={formData?.boahubLocationId}
                onChange={(e, data) =>
                  handleChange("boahubLocationId", data.value)
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => {
              onSubmitJob(
                formData?.jobStatusId === 3 ? 3 : formData?.jobStatusId + 1,
                true
              );
            }}
          >
            SAVE
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => setOpenHoseMaker(false)}
        onOpen={() => setOpenHoseMaker(true)}
        open={openHoseMaker}
        className="hosemaker-modal"
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <HoseMakerNew
            onCancelHoseMaker={onCancelHoseMaker}
            data={selectedData}
            addBOM={addBOM}
            updateBOM={updateBOM}
            hoseMakerData={hoseMakerData}
            hoseMakerIndex={hoseMakerIndex}
            assetImage={assetImage}
            assetType={assetType}
            currentStatus={currentStatus}
            isAssemble={isAssemble}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setOpenOtherComponents(false)}
        onOpen={() => setOpenOtherComponents(true)}
        open={openOtherComponents}
        className="other-components-modal"
      >
        <Modal.Content>
          <SearchComponent
            isHoseMaker={true}
            isJobbing={true}
            onProductClick={addOtherComponents}
          />
        </Modal.Content>
      </Modal>
      <Modal
        size="mini"
        onClose={() => setOpenCustomItem(false)}
        onOpen={() => setOpenCustomItem(true)}
        open={openCustomItem}
        className="remove-bom"
        closeIcon
      >
        <Modal.Content>
          <Grid columns={1} style={{ margin: 0, marginTop: 10 }}>
            <Grid.Row style={{ padding: 0 }}>
              <Grid.Column
                width={16}
                style={{
                  padding: 0,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                <Header as="h4">Add Custom Item</Header>
                <Input
                  size="mini"
                  style={{ width: "70%" }}
                  value={customProductText}
                  onChange={(e, data) => setcCustomProductText(data.value)}
                />
                <Button
                  disabled={!customProductText}
                  positive
                  size="mini"
                  style={{ marginLeft: 5 }}
                  onClick={() => addCustomComponents()}
                >
                  <Icon name="plus" />
                  Add
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
      <Modal
        size="mini"
        open={openRemoveBOM}
        onClose={() => removeBOM(false)}
        className="remove-bom"
      >
        <Modal.Header>Remove BOM</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to remove BOM?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => removeBOM(false)}>Cancel</Button>
          <Button negative onClick={() => removeBOM(true)}>
            Remove
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="mini"
        open={openToInprogress}
        onClose={() => setOpenToInprogress(false)}
        className="remove-bom"
      >
        <Modal.Header>Move back to "In Progress"</Modal.Header>
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <p>
              Are you sure you want to move #
              {selectedData?.jobDetails?.jobNumber} from "
              {formData?.jobStatusId == 3 ? "Completed" : "Archived"}" back to
              "In Progress"?
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenToInprogress(false)}>
            No
          </Button>
          <Button positive onClick={() => onSubmitJob(2, false)}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="mini"
        open={openToInvoiced}
        onClose={() => setOpenToInvoiced(false)}
        className="remove-bom"
      >
        <Modal.Header>Move to Invoiced</Modal.Header>
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <p>
              Are you sure you want to move #
              {selectedData?.jobDetails?.jobNumber} to "Invoiced"?
            </p>
          </div>
          <div style={{ textAlign: "center", marginTop: 3 }}>
            <p>It cannot be moved back.</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenToInvoiced(false)}>
            No
          </Button>
          <Button positive onClick={() => onSubmitJob(4, false)}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="mini"
        open={openSendJob}
        onClose={() => setOpenSendJob(false)}
        className="remove-bom"
      >
        <Modal.Content
          style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
        >
          {isLoading ? (
            <Dimmer active inverted>
              <Loader size="large"></Loader>
            </Dimmer>
          ) : null}
          <div style={{ textAlign: "center" }}>
            <span>How would you like to send</span>
            <br />
            <span>#{selectedData?.jobDetails?.jobNumber}?</span>
          </div>
          <Grid columns={1} style={{ margin: 0, marginTop: 10 }}>
            <Grid.Row style={{ padding: 0 }}>
              <Grid.Column width={16} style={{ padding: 0, margin: 0 }}>
                <Button
                  basic
                  icon="file pdf outline"
                  content="PDF for Internal Use"
                  style={{ width: "100%", borderRadius: 0, fontSize: 16 }}
                  onClick={() => onGeneratePDF(1)}
                />
              </Grid.Column>
              <Grid.Column width={16} style={{ padding: 0, margin: 0 }}>
                <Button
                  basic
                  icon="file pdf outline"
                  content="PDF for Customer"
                  style={{ width: "100%", borderRadius: 0, fontSize: 16 }}
                  onClick={() => onGeneratePDF(2)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
      <Modal
        size="mini"
        open={openIsDuplicateCustomer}
        onClose={() => setOpenIsDuplicateCustomer(false)}
        className="remove-bom"
      >
        <Modal.Header style={{ backgroundColor: "rgb(255, 143, 143)" }}>
          WARNNING
        </Modal.Header>
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <p>{duplicateMessage}</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenIsDuplicateCustomer(false)}>OK</Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={deleteFileOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure <br /> you want to delete image?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDeleteFileOpen(false)}>NO</Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onDeleteFile()}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={discardInvoiceOpen}>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure <br /> to discard the changes?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDiscardInvoiceOpen(false)}>NO</Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => {
              setInvoice({
                invoiceStatus: 1,
                contactId: "",
                invoiceID: null,
              });
              setDiscardInvoiceOpen(false);
            }}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      {isViewerOpen && (
        <Modal className="hosemaker-modal" open={true}>
          <Modal.Content style={{ height: 700 }}>
            {isViewerOpen && (
              <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </Modal.Content>
        </Modal>
      )}
      {isViewerOpen && (
        <Modal className="hosemaker-modal" open={true}>
          <Modal.Content style={{ height: 700 }}>
            {isViewerOpen && (
              <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </Modal.Content>
        </Modal>
      )}
      {isAdditionalViewerOpen && (
        <Modal className="hosemaker-modal" open={true}>
          <Modal.Content style={{ height: 700 }}>
            {isAdditionalViewerOpen && (
              <ImageViewer
                src={additionalImages}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeAdditionalImageViewer}
              />
            )}
          </Modal.Content>
        </Modal>
      )}
      <Modal
        size="mini"
        onClose={() => {
          setManualTimeEntry({
            id: 0,
            jobId: "",
            notes: null,
            timeSpanMinutes: null,
          });
          setOpenManualTimeEntry(false);
        }}
        onOpen={() => setOpenManualTimeEntry(true)}
        open={openManualTimeEntry}
        className="remove-bom"
        closeIcon
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <Grid columns={1} style={{ margin: 0, marginTop: 10 }}>
            <Grid.Row style={{ padding: 0 }}>
              <Grid.Column
                width={16}
                style={{
                  padding: 0,
                  margin: 0,
                  marginBottom: "1rem",
                  textAlign: "center",
                }}
              >
                <Header as="h4">Add Time Entry</Header>
              </Grid.Column>
              <Grid.Column width={12} style={{}}>
                <Form>
                  <Form.Field>
                    <TextArea
                      name="notes"
                      placeholder="Reason for manual time entry"
                      value={manualTimeEntry?.notes}
                      onChange={handleTimeEntryChange}
                    />
                  </Form.Field>
                </Form>
              </Grid.Column>
              <Grid.Column width={4} style={{ padding: 0 }}>
                <Input
                  name="timeSpanMinutes"
                  type="number"
                  placeholder="Hr"
                  size="mini"
                  style={{
                    width: "100%",
                    marginBottom: "0.5rem",
                    textAlign: "center",
                    height: 40,
                  }}
                  input={{ style: { textAlign: "center" } }}
                  value={manualTimeEntry?.timeSpanMinutes}
                  onChange={handleTimeEntryChange}
                />
                <Button
                  disabled={
                    isLoading ||
                    !manualTimeEntry.notes ||
                    !manualTimeEntry.timeSpanMinutes
                  }
                  positive
                  size="mini"
                  style={{ width: "100%", height: 40 }}
                  onClick={() => onSaveManualTimeEntry()}
                >
                  <Icon name="plus" />
                  Add
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
      <Grid
        columns={3}
        divided
        className="job-management"
        style={{ marginTop: -30 }}
      >
        <Grid.Row style={{ paddingTop: 0, zIndex: 1 }}>
          <Grid.Column width={16} style={{ paddingRight: 0 }}>
            <h3 style={{ marginLeft: 20 }}>
              <Icon name="configure" /> Jobbing -{" "}
              {localStorage.getItem("stockLocationName")}
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column
            computer={4}
            tablet={6}
            mobile={16}
            className="job-list-section"
            style={{ paddingRight: 0 }}
          >
            <div className="job-header">
              <Input
                icon="search"
                placeholder="Search..."
                style={{ width: "100%" }}
                size="small"
                onChange={(e, data) => setSearchTerm(data.value)}
                value={searchTerm}
              />
            </div>
            <hr />
            <div
              className="job-status"
              style={{ paddingRight: "0px !important" }}
            >
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <Select
                      style={{ fontSize: 13, width: "100%" }}
                      options={jobStatusList}
                      value={filterStatus}
                      onChange={(e, data) => setFilterStatus(data.value)}
                    />
                  </Grid.Column>
                  <Grid.Column
                    width={16}
                    style={{ paddingTop: 8, paddingBottom: 0 }}
                  >
                    <Dropdown
                      style={{ fontSize: 13, width: "100%" }}
                      basic
                      color="black"
                      text={
                        technicianFilter
                          ? technicianName
                          : "Filter by technician"
                      }
                      search
                      scrolling
                      icon="user"
                      floating
                      labeled
                      button
                      className="icon"
                      options={technicianFilterList}
                      onChange={(e, data) => {
                        setTechnicianFilter(data.value);
                        setTechnicianName(data.text);
                      }}
                      value={technicianFilter}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div style={{ marginTop: 5 }}>
                <Button
                  className="active-status"
                  icon="add"
                  size="mini"
                  style={{ width: "100%", fontSize: 14 }}
                  onClick={() => onNewJob()}
                >
                  <Icon name="add" style={{ color: "#fff", marginLeft: -15 }} />
                  New Job
                </Button>
              </div>
            </div>
            <div className="job-list">
              <div style={{ position: "absolute", top: 300, left: 95 }}>
                {searchLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  >
                    Loading Job Cards...
                  </Loader>
                ) : null}
              </div>
              <div
                {...events}
                ref={ref}
                className="job-list-scroll"
                disabled={searchLoading}
                style={{ opacity: searchLoading ? 0.2 : 1 }}
              >
                {jobListGrouped}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            computer={8}
            tablet={10}
            mobile={16}
            style={{ paddingRight: 0 }}
          >
            {selectedData ? (
              <div className="job-form">
                <div className="job-actions">
                  <Container
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      height: 45,
                      marginLeft: 0,
                      paddingLeft: 0,
                    }}
                  >
                    {formData?.jobStatusId !== 5 && (
                      <Button
                        style={{
                          backgroundColor: "#979fa8",
                          minWidth: 100,
                        }}
                        size="small"
                        onClick={() =>
                          onSubmitJob(formData?.jobStatusId, false)
                        }
                      >
                        Save & Close
                      </Button>
                    )}
                    {formData?.jobStatusId < 3 &&
                      formData?.jobStatusId !== 5 &&
                      !formData?.isConflict && (
                        <Button
                          style={{
                            backgroundColor: "#66cc23",
                            color: "#fff",
                          }}
                          size="small"
                          onClick={() => {
                            if (formData?.jobStatusId === 2) {
                              if (stocklocationList?.length > 1) {
                                onSubmitJob(
                                  formData?.jobStatusId === 3
                                    ? 3
                                    : formData?.jobStatusId + 1,
                                  true
                                );
                                // setOpenSelectStockLocation(true);
                              } else {
                                handleChange(
                                  "boahubLocationId",
                                  stocklocationList[0]?.id
                                );
                                onSubmitJob(
                                  formData?.jobStatusId === 3
                                    ? 3
                                    : formData?.jobStatusId + 1,
                                  true
                                );
                              }
                            } else {
                              onSubmitJob(
                                formData?.jobStatusId === 3
                                  ? 3
                                  : formData?.jobStatusId + 1,
                                true
                              );
                            }
                          }}
                        >
                          Save & Progress
                        </Button>
                      )}
                    {formData?.jobStatusId === 2 && (
                      <Card style={{ width: 230 }}>
                        <Card.Content
                          style={{
                            padding: 0,
                            height: 33.41,
                            paddingTop: 3.5,
                            paddingLeft: 10,
                            paddingRight: 10,
                          }}
                        >
                          <Card.Description>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "1rem",
                                  fontSize: 15,
                                  fontWeight: "bold",
                                }}
                              >
                                Time Clock
                              </span>
                              <span
                                style={{ marginRight: "1rem", fontSize: 13 }}
                              >
                                {formatTime(seconds)}
                              </span>
                              {!running ? (
                                <Button
                                  circular
                                  icon
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "26px",
                                    height: "26px",
                                    backgroundColor: "#66cc23",
                                    color: "#fff",
                                    paddingLeft: 10,
                                  }}
                                  onClick={toggleTimer}
                                >
                                  <Icon
                                    name="play"
                                    style={{
                                      margin: 0,
                                    }}
                                  />
                                </Button>
                              ) : (
                                <Button
                                  circular
                                  icon
                                  color="youtube"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "26px",
                                    height: "26px",
                                  }}
                                  onClick={toggleTimer}
                                >
                                  <Icon
                                    name="stop"
                                    style={{
                                      margin: 0,
                                    }}
                                  />
                                </Button>
                              )}
                            </div>
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    )}
                    {formData?.jobStatusId > 2 &&
                      formData?.jobStatusId !== 5 &&
                      !formData?.isConflict && (
                        <Button
                          style={{
                            backgroundColor: "#66cc23",
                            color: "#fff",
                            minWidth: 100,
                          }}
                          size="small"
                          onClick={() => {
                            setOpenSendJob(true);
                          }}
                        >
                          Download
                        </Button>
                      )}
                    {formData?.jobStatusId === 5 ? (
                      <Button
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                          backgroundColor: "#66cc23",
                          color: "#fff",
                          minWidth: 100,
                        }}
                        size="small"
                        onClick={() => onRestoreJob()}
                      >
                        <Icon name="undo" />
                        Restore
                      </Button>
                    ) : null}
                    {formData?.jobStatusId === 3 ? (
                      <Button
                        color="orange"
                        size="small"
                        onClick={() => setOpenToInprogress(true)}
                      >
                        Move back to "In Progress"
                      </Button>
                    ) : null}
                    {formData?.jobStatusId === 3 ? (
                      <Button
                        color="facebook"
                        size="small"
                        onClick={() => setOpenToInvoiced(true)}
                      >
                        Move to Invoiced
                      </Button>
                    ) : null}
                    {formData?.jobStatusId > 0 &&
                    formData?.jobStatusId < 5 &&
                    formData?.id &&
                    !formData?.isConflict ? (
                      <Button
                        color="google plus"
                        size="small"
                        onClick={() => onArchiveJob()}
                        style={{
                          position: "absolute",
                          right: formData?.jobStatusId === 1 ? 115 : 12,
                          minWidth: 100,
                        }}
                      >
                        Archive
                      </Button>
                    ) : null}
                    {(formData?.jobStatusId === 1 ||
                      formData?.jobStatusId === 5 ||
                      formData?.isConflict) &&
                    formData?.id ? (
                      <Button
                        color="youtube"
                        size="small"
                        onClick={() => onDeleteJob()}
                        style={{
                          position: "absolute",
                          right: 12,
                          paddingLeft: 15,
                          paddingRight: 15,
                          minWidth: 100,
                        }}
                      >
                        <Icon name="trash" />
                        Delete
                      </Button>
                    ) : null}
                  </Container>
                </div>
                <hr />
                <div
                  className="job-form-content"
                  style={{ paddingRight: "0px !important" }}
                >
                  <Form size="tiny">
                    <Grid>
                      <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column
                          width={16}
                          style={{ paddingBottom: 0 }}
                          disabled={formData?.jobStatusId > 2}
                        >
                          <div class="arrow-steps clearfix">
                            <div
                              className={
                                formData?.jobStatusId == 0 ||
                                formData?.jobStatusId == 1
                                  ? "step current"
                                  : "step"
                              }
                            >
                              <span>
                                <a>Job Request</a>
                              </span>
                            </div>
                            <div
                              className={
                                formData?.jobStatusId == 2
                                  ? "step current"
                                  : "step"
                              }
                            >
                              <span>
                                <a>
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Work in
                                  Progress &nbsp;
                                </a>
                              </span>
                            </div>
                            <div
                              className={
                                formData?.jobStatusId == 3
                                  ? "step current"
                                  : "step"
                              }
                            >
                              <span>
                                <a>&nbsp; &nbsp; &nbsp; Completed</a>
                              </span>
                            </div>
                            <div
                              className={
                                formData?.jobStatusId == 4
                                  ? "step current"
                                  : "step"
                              }
                            >
                              <span>
                                <a>&nbsp; &nbsp; &nbsp; Invoiced</a>
                              </span>
                            </div>
                            {formData?.jobStatusId === 5 && (
                              <div
                                class="step archived"
                                style={{
                                  position: "absolute",
                                  right: 30,
                                  backgroundColor: "red !important",
                                }}
                              >
                                <span>
                                  <a>Archived</a>
                                </span>
                              </div>
                            )}
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          width={16}
                          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
                          disabled={formData?.jobStatusId > 2}
                        >
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            {selectedData && selectedData.jobDetails?.jobNumber
                              ? `Job #${formData?.jobNumber}`
                              : `New Job Request`}
                          </Header>
                        </Grid.Column>
                        {selectedData && formData?.isConflict ? (
                          <Grid.Column
                            width={16}
                            style={{ paddingTop: 0, paddingBottom: "1rem" }}
                            disabled={formData?.jobStatusId > 2}
                          >
                            <Message negative>
                              <Message.Header
                                style={{
                                  textAlign: "center",
                                  fontSize: 18,
                                  paddingBottom: "0.5rem",
                                }}
                              >
                                Important Notice:
                              </Message.Header>
                              <p style={{ fontSize: 15 }}>
                                Job card duplicated from Job Card{" "}
                                {formData?.jobNumber?.replace("COPY-", "")}, due
                                to online/offline conflict. Please check both
                                job cards, merge details then delete this one.{" "}
                              </p>
                            </Message>
                          </Grid.Column>
                        ) : null}

                        <Grid.Column
                          computer={1}
                          tablet={2}
                          mobile={2}
                          style={{
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            paddingRight: 0,
                          }}
                          disabled={formData?.jobStatusId > 2}
                        >
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{ marginTop: -8.5 }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={15}
                          tablet={14}
                          mobile={14}
                          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
                          disabled={formData?.jobStatusId > 2}
                        >
                          <Header as="h5" style={{ marginLeft: -15 }}>
                            Customer Details
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          disabled={formData?.jobStatusId > 2}
                        >
                          <Form.Field required>
                            <label>Customer</label>
                            <Grid columns={2} style={{ padding: 0 }}>
                              <Grid.Column
                                width={12}
                                style={{ paddingRight: 0 }}
                              >
                                {formData?.selectCustomer ? (
                                  <CustomerSearch
                                    customerList={customerList}
                                    onSelectCustomer={setSelectedCustomer}
                                    companyName={formData?.companyName}
                                  />
                                ) : (
                                  <Input
                                    className={
                                      formData?.companyName === "" &&
                                      formData?.jobStatusId > 0
                                        ? "error"
                                        : ""
                                    }
                                    value={formData?.companyName}
                                    onChange={(e, data) =>
                                      handleChange("companyName", data.value)
                                    }
                                  />
                                )}
                              </Grid.Column>
                              <Grid.Column
                                width={4}
                                style={{
                                  lineHeight: "1",
                                  textAlign: "center",
                                  paddingLeft: 5,
                                }}
                              >
                                <Button
                                  style={{
                                    fontSize: 11,
                                    lineHeight: "1.1",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  onClick={() =>
                                    handleChange(
                                      "selectCustomer",
                                      formData?.selectCustomer ? false : true
                                    )
                                  }
                                >
                                  {!formData?.selectCustomer
                                    ? "Search Customer"
                                    : "Input Manually"}
                                </Button>
                              </Grid.Column>
                            </Grid>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          style={{ paddingLeft: 0 }}
                          disabled={formData?.jobStatusId > 2}
                        >
                          <Form.Field>
                            <label>Purchase Order #</label>
                            <Input
                              value={formData?.customerPurchaseOrderNumber}
                              onChange={(e, data) =>
                                handleChange(
                                  "customerPurchaseOrderNumber",
                                  data.value
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={8} style={{ paddingTop: 15 }}>
                          <Form.Field disabled={formData?.jobStatusId > 2}>
                            <label>Contact Name</label>
                            {!formData?.selectCustomer ? (
                              <Input
                                value={formData?.contactName}
                                onChange={(e, data) =>
                                  handleChange("contactName", data.value)
                                }
                              />
                            ) : (
                              <Grid columns={2} style={{ padding: 0 }}>
                                <Grid.Column
                                  width={12}
                                  style={{ paddingRight: 0 }}
                                >
                                  {formData?.selectContact ? (
                                    <ContactSearch
                                      customerList={customerList}
                                      customerId={selectedCustomer.id}
                                      onSelectContact={setSelectedContact}
                                      contactName={formData?.contactName}
                                    />
                                  ) : (
                                    <Input
                                      value={formData?.contactName}
                                      onChange={(e, data) =>
                                        handleChange("contactName", data.value)
                                      }
                                    />
                                  )}
                                </Grid.Column>
                                <Grid.Column
                                  width={4}
                                  style={{
                                    lineHeight: "1",
                                    textAlign: "center",
                                    paddingLeft: 5,
                                  }}
                                >
                                  <Button
                                    className={
                                      formData?.makeId === 0 && "active-status"
                                    }
                                    style={{
                                      fontSize: 11,
                                      lineHeight: "1.1",
                                      fontWeight: "bold",
                                      padding: "5px 5px",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    onClick={() =>
                                      handleChange(
                                        "selectContact",
                                        formData?.selectContact ? false : true
                                      )
                                    }
                                  >
                                    {!formData?.selectContact
                                      ? "Search Contact"
                                      : "Input Manually"}
                                  </Button>
                                </Grid.Column>
                              </Grid>
                            )}
                          </Form.Field>
                          <Form.Field disabled={formData?.jobStatusId > 2}>
                            <label>Email Address</label>
                            <Input
                              value={formData?.contactEmail}
                              onChange={(e, data) =>
                                handleChange("contactEmail", data.value)
                              }
                            />
                          </Form.Field>
                          <Form.Field disabled={formData?.jobStatusId > 2}>
                            <label>Contact Number</label>
                            <Input
                              value={formData?.contactNumber}
                              onChange={(e, data) =>
                                handleChange("contactNumber", data.value)
                              }
                            />
                          </Form.Field>
                          {(formData?.selectCustomer &&
                            formData?.selectContact) ||
                          (formData?.customer?.id &&
                            formData.customerContact?.id) ? null : (
                            <Form.Field
                              disabled={formData?.jobStatusId > 2}
                              style={{
                                paddingTop: "0.5rem",
                                paddingBottom: "0.5rem",
                              }}
                            >
                              <Checkbox
                                className="custom-checkbox"
                                checked={formData?.saveContactDetails}
                                label="Save customer details"
                                style={{ color: "red" }}
                                onChange={(e, data) =>
                                  handleChange(
                                    "saveContactDetails",
                                    data.checked
                                  )
                                }
                              />
                            </Form.Field>
                          )}
                          <Form.Field>
                            <label
                              style={{
                                opacity: formData?.jobStatusId > 2 ? 0.6 : 1,
                              }}
                            >
                              Location
                            </label>
                            <Grid columns={2} style={{ padding: 0 }}>
                              <Grid.Column
                                width={12}
                                style={{ paddingRight: 0 }}
                              >
                                <Autocomplete
                                  disabled={formData?.jobStatusId > 2}
                                  ref={locationRef}
                                  style={{ width: "100%" }}
                                  placeholder=""
                                  onPlaceSelected={(place, inputRef) => {
                                    onAddressSelection(place);
                                  }}
                                  types={["geocode"]}
                                  componentRestrictions={{
                                    country: countries,
                                  }}
                                  value={formData?.customerLocation}
                                  onChange={(e, data) =>
                                    handleChange("customerLocation", data)
                                  }
                                />
                              </Grid.Column>
                              <Grid.Column
                                width={4}
                                style={{
                                  lineHeight: "1",
                                  textAlign: "center",
                                  paddingLeft: 5,
                                }}
                              >
                                <Button
                                  className={
                                    formData?.makeId === 0 && "active-status"
                                  }
                                  style={{
                                    fontSize: 12,
                                    lineHeight: "1.1",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  onClick={openGoogleMaps}
                                >
                                  Go to maps
                                </Button>
                              </Grid.Column>
                            </Grid>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          style={{ padding: "15px 18px 0px 0px" }}
                          disabled={formData?.jobStatusId > 2}
                        >
                          <div style={{ height: "100%", width: "100%" }}>
                            <GoogleMapReact
                              bootstrapURLKeys={{ key: "" }}
                              defaultCenter={{
                                lat: -42.312144,
                                lng: 172.474153,
                              }}
                              defaultZoom={5}
                              zoom={hasLocation ? 11 : 5}
                              center={
                                hasLocation
                                  ? location
                                  : {
                                      lat: -42.312144,
                                      lng: 172.474153,
                                    }
                              }
                            ></GoogleMapReact>
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          computer={1}
                          tablet={2}
                          mobile={2}
                          style={{
                            paddingTop: "2rem",
                            paddingBottom: "1rem",
                            paddingRight: 0,
                          }}
                          disabled={formData?.jobStatusId > 2}
                        >
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{ marginTop: -12.5 }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          disabled={formData?.jobStatusId > 2}
                          computer={15}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: "2rem",
                            paddingBottom: "1rem",
                          }}
                        >
                          <Header as="h5" style={{ marginLeft: -15 }}>
                            Job Details
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          width={16}
                          style={{ paddingTop: 0 }}
                          disabled={formData?.jobStatusId > 2}
                        >
                          <Form>
                            <Form.Field>
                              <label>Job Description</label>
                              <TextArea
                                onChange={(e, data) =>
                                  handleChange("jobDesc", data.value)
                                }
                                value={formData?.jobDesc}
                              />
                            </Form.Field>
                          </Form>
                        </Grid.Column>
                        {jobImages?.length > 0 ? (
                          <>
                            <Grid.Column
                              width={16}
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                              }}
                            >
                              <Form style={{ marginTop: "1rem" }}>
                                <Form.Field>
                                  <label>Job Description Images</label>
                                </Form.Field>
                              </Form>
                              <Grid style={{ padding: 0, margin: 0 }}>
                                {jobImages.map((data, index) => {
                                  return (
                                    <Grid.Column
                                      width={4}
                                      style={{ padding: 0, margin: 0 }}
                                    >
                                      <div
                                        className="ui image"
                                        style={{
                                          height: 130,
                                          width: 130,
                                          marginTop: 10,
                                        }}
                                      >
                                        <img
                                          onClick={() => openImageViewer(index)}
                                          src={data.filePath}
                                          alt="Image Preview"
                                          className="bomImage"
                                          style={{
                                            marginTop: "auto",
                                            marginBottom: "auto",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </Grid.Column>
                                  );
                                })}
                              </Grid>
                            </Grid.Column>
                          </>
                        ) : null}
                        <Grid.Column
                          width={8}
                          disabled={formData?.jobStatusId > 2}
                          style={{ paddingTop: "1.5rem" }}
                        >
                          <Form.Field disabled={formData?.jobStatusId > 2}>
                            <label>Urgency</label>
                            <Select
                              options={urgenciesData}
                              value={formData?.urgencyId}
                              onChange={(e, data) =>
                                handleChange("urgencyId", data.value)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          disabled={formData?.jobStatusId > 2}
                          style={{ paddingTop: "1.5rem" }}
                        >
                          <Form.Field disabled={formData?.jobStatusId > 2}>
                            <label>Due date</label>
                            <DatePicker
                              dateFormat="dd/MM/yy hh:mm aa"
                              selected={selectedDate}
                              showTimeSelect
                              timeIntervals={15}
                              onChange={(date) =>
                                handleChange("dueDateTime", date)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          disabled={formData?.jobStatusId > 2}
                          style={{ paddingTop: "1.5rem" }}
                        >
                          <Form.Field disabled={formData?.jobStatusId > 2}>
                            <label>Technician</label>
                            <Select
                              text={
                                technicianDeleted &&
                                !formData?.technicianSelected
                                  ? formData?.technician
                                  : ""
                              }
                              search
                              options={technicianList}
                              value={formData?.technicianEmail}
                              selectOnBlur={false}
                              selectOnNavigation={false}
                              onOpen={() => {
                                setTechnicianDeleted(false);
                              }}
                              onClose={() => {
                                if (formData?.technicianEmail) {
                                  //find technician if exist
                                  let technician = technicianList.find(
                                    (item) =>
                                      item.email === formData?.technicianEmail
                                  );
                                  if (!technician) {
                                    setTechnicianDeleted(true);
                                  }
                                }
                              }}
                              onChange={(e, data) =>
                                handleChange("technicianEmail", data.value)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      {formData?.jobStatusId === 2 ? (
                        <>
                          <Grid.Column
                            width={8}
                            style={{
                              marginTop: 30,
                              marginBottom: 15,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Header
                              as="h5"
                              style={{
                                color: "#66cc23",
                              }}
                            >
                              Hose Assemblies
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={3}
                            style={{
                              marginTop: 35,
                              marginBottom: 15,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          ></Grid.Column>
                          <Grid.Column
                            width={5}
                            style={{
                              marginTop: 25,
                              marginBottom: 15,
                              paddingRight: 15,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Button
                              className="active-status"
                              size="small"
                              style={{
                                width: "100%",
                                backgroundColor: "rgb(102, 204, 35)",
                                color: "#fff",
                                marginLeft: "auto",
                              }}
                              onClick={() => {
                                setIsAssemble(false);
                                onAutoSaveJob(formData);
                                setHoseMakerIndex(null);
                                setHoseMakerData(null);
                                setOpenHoseMaker(true);
                              }}
                            >
                              Add Hose Assembly
                            </Button>
                          </Grid.Column>
                        </>
                      ) : null}
                      {formData?.jobStatusId > 2 ? (
                        <>
                          <Grid.Column
                            width={8}
                            style={{
                              marginTop: 30,
                              marginBottom: 15,
                            }}
                          >
                            <Header
                              as="h5"
                              style={{
                                color: "#66cc23",
                              }}
                            >
                              Hose Assemblies
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={8}
                            style={{
                              marginTop: 35,
                              marginBottom: 15,
                            }}
                          ></Grid.Column>
                        </>
                      ) : null}
                      <Grid.Column
                        width={16}
                        style={{
                          padding: "0px 17px",
                        }}
                      >
                        {formData?.hoseMaker?.map((data, index) => {
                          return (
                            <Card
                              style={{
                                border: "2px solid #bdbdbd",
                                width: "100%",
                              }}
                            >
                              <Card.Content style={{ maxHeight: 220 }}>
                                <Grid>
                                  <Grid.Column
                                    style={{
                                      paddingTop: 10,
                                      paddingLeft: 20,
                                      paddingBottom: 5,
                                      cursor: "pointer",
                                    }}
                                    width={7}
                                    disabled={formData?.jobStatusId > 2}
                                    onClick={() => {
                                      setIsAssemble(false);
                                      onAutoSaveJob(formData);
                                      setHoseMakerIndex(index);
                                      setHoseMakerData(data);
                                      setOpenHoseMaker(true);
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "bolder",
                                        fontSize: 18,
                                      }}
                                    >
                                      <span>HOSE ITEM #{index + 1}</span>
                                    </div>
                                    <Grid>
                                      <Grid.Column width={8}>
                                        <div
                                          style={{
                                            paddingTop: 7,
                                            fontSize: 14,
                                          }}
                                        >
                                          <span>Hose SKU:</span>
                                          <br />
                                          <span>Hose Name:</span>
                                          <br />
                                          <span>OEM Number:</span>
                                          <br />
                                          <span>OAL:</span>
                                          <br />
                                          <span>QTY:</span>
                                          {localStorage.getItem(
                                            "boahubIsRS23"
                                          ) === "true" &&
                                            data.isRS23Compliant == true && (
                                              <>
                                                <br />
                                                <span>RS23 Unit Price:</span>
                                                <br />
                                                <span>RS23 Sub-total:</span>
                                              </>
                                            )}
                                        </div>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={8}
                                        style={{
                                          paddingLeft: 0,
                                          textAlign: "right",
                                        }}
                                      >
                                        <div
                                          style={{
                                            paddingTop: 7,
                                            fontSize: 14,
                                            textAlign: "right",
                                            display: "inline-block", // Ensure the span is inline-block
                                            whiteSpace: "nowrap", // Prevent line breaks
                                            overflow: "hidden", // Hide overflow content
                                            textOverflow: "ellipsis", // Show ellipsis when content overflows
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span>
                                            {data.step1_Barcode
                                              ? data.step1_Barcode
                                              : ""}
                                          </span>
                                          <br />
                                          <span>
                                            {data.hoseIdTagging[0]?.hoseName
                                              ? data.hoseIdTagging[0]?.hoseName
                                              : ""}
                                          </span>
                                          <br />
                                          <span>
                                            {data.hoseIdTagging[0]
                                              ?.hoseOemNumber
                                              ? data.hoseIdTagging[0]
                                                  ?.hoseOemNumber
                                              : ""}
                                          </span>
                                          <br />
                                          <span>
                                            {data.oal ? data.oal + "mm" : ""}
                                          </span>
                                          <br />
                                          <span>
                                            {data.quantity ? data.quantity : ""}
                                          </span>
                                          {localStorage.getItem(
                                            "boahubIsRS23"
                                          ) === "true" &&
                                            data.isRS23Compliant == true && (
                                              <>
                                                <br />
                                                <span
                                                  style={
                                                    formData?.jobStatusId > 2
                                                      ? null
                                                      : {
                                                          cursor: "pointer",
                                                          color: "#007bff",
                                                          textDecoration:
                                                            "underline", // This adds an underline to the text
                                                          border: "none", // Ensures no border is shown by default
                                                          padding: "2px", // Optional: Adds padding to make the border look nicer when it appears
                                                        }
                                                  }
                                                  onClick={(e) =>
                                                    changeRS23priceHandler(
                                                      e,
                                                      index,
                                                      data.rS23ComplianceRate
                                                    )
                                                  }
                                                  onMouseEnter={(e) =>
                                                    (e.currentTarget.style.border =
                                                      "solid 1px #007bff")
                                                  } // Adds a border on mouse enter
                                                  onMouseLeave={(e) =>
                                                    (e.currentTarget.style.border =
                                                      "none")
                                                  } // Removes the border on mouse leave
                                                >
                                                  ${" "}
                                                  {data.rS23ComplianceRate
                                                    ? data.rS23ComplianceRate.toFixed(
                                                        2
                                                      )
                                                    : `${(0).toFixed(2)}`}
                                                </span>
                                                <br />
                                                <span>
                                                  ${" "}
                                                  {data.rS23ComplianceRate
                                                    ? (
                                                        data.rS23ComplianceRate *
                                                        data.quantity
                                                      ).toFixed(2)
                                                    : `${(0).toFixed(2)}`}
                                                </span>
                                              </>
                                            )}
                                        </div>
                                      </Grid.Column>
                                    </Grid>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={5}
                                    style={{
                                      textAlign: "center",
                                      paddingTop: 10,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                      textOverflow: "nowrap",
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingTop: 6,
                                        fontSize: 12,
                                        textOverflow: "nowrap",
                                      }}
                                    >
                                      <span>
                                        Location:{" "}
                                        {getPositionName(data.positionId)}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Image
                                        style={{
                                          width: 120,
                                          height: 120,
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                        }}
                                        src={getPositionImage(data.positionId)}
                                        onClick={
                                          formData?.jobStatusId === 2
                                            ? () => {
                                                setIsAssemble(false);
                                                onAutoSaveJob(formData);
                                                setHoseMakerIndex(index);
                                                setHoseMakerData(data);
                                                setOpenHoseMaker(true);
                                              }
                                            : null
                                        }
                                      />
                                    </div>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={4}
                                    style={{
                                      padding: 0,
                                      paddingTop: 60,
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                      textAlign: "center",
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      style={{
                                        width: "80%",
                                        border: data.bomAssembly
                                          ?.lfActualCrimpSize
                                          ? "Solid 2px #66cc23"
                                          : null,
                                        backgroundColor: data.bomAssembly
                                          ?.lfActualCrimpSize
                                          ? "#fff"
                                          : "#FFAD00",
                                        color: data.bomAssembly
                                          ?.lfActualCrimpSize
                                          ? "#66cc23"
                                          : "#fff",
                                        fontSize: 12,
                                        padding: "10px 5px",
                                        opacity: "1 !important",
                                      }}
                                      onClick={() => {
                                        setIsAssemble(true);
                                        if (formData?.jobStatusId === 2)
                                          onAutoSaveJob(formData);
                                        setHoseMakerIndex(index);
                                        setHoseMakerData(data);
                                        setOpenHoseMaker(true);
                                      }}
                                    >
                                      {data.bomAssembly?.lfActualCrimpSize
                                        ? "View Assembled BOM"
                                        : "Assemble BOM"}
                                    </Button>
                                    {data.bomAssembly?.lfActualCrimpSize ? (
                                      <div
                                        style={{
                                          paddingLeft: 2,
                                          marginTop: 35,
                                          marginRight: 10,
                                          fontSize: 12,
                                          color: "#AFABAB",
                                        }}
                                      >
                                        <label>
                                          Assembled at:{" "}
                                          {formatDateTime(
                                            data.bomAssembly.bomCreated
                                          )}
                                        </label>
                                      </div>
                                    ) : null}
                                  </Grid.Column>
                                </Grid>
                              </Card.Content>
                            </Card>
                          );
                        })}
                      </Grid.Column>
                      {openRS23Price && (
                        <RS23SetPriceForm
                          hosetitle={rs23PriceTitle}
                          hoseIndex={selectedRS23Index}
                          priceText={rs23Price}
                          closeForm={closeRS23PriceForm}
                          jobData={formData}
                          setJobData={setFormData}
                        ></RS23SetPriceForm>
                      )}
                      {localStorage.getItem("boahubIsRS23") === "true" &&
                        formData?.hoseMaker?.length > 0 &&
                        formData?.jobStatusId == 2 && (
                          <div
                            style={{
                              color: "#AFABAB",
                              paddingTop: 5,
                            }}
                          >
                            * Click the unit price to edit.
                          </div>
                        )}
                      <Grid.Row
                        disabled={formData?.jobStatusId > 2}
                        style={{ padding: 0 }}
                      >
                        {formData?.jobStatusId === 2 ? (
                          <>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 30,
                                marginBottom: 15,
                              }}
                            >
                              <Header
                                as="h5"
                                style={{
                                  color: "#66cc23",
                                }}
                              >
                                Inventory Components
                              </Header>
                            </Grid.Column>
                            <Grid.Column
                              width={3}
                              style={{
                                marginTop: 35,
                                marginBottom: 15,
                              }}
                            ></Grid.Column>
                            <Grid.Column
                              width={5}
                              style={{
                                marginTop: 25,
                                marginBottom: 15,
                                paddingRight: 15,
                              }}
                            >
                              <Button
                                className="active-status"
                                size="small"
                                style={{
                                  width: "100%",
                                  backgroundColor: "rgb(102, 204, 35)",
                                  color: "#fff",
                                  marginLeft: "auto",
                                }}
                                onClick={() => setOpenOtherComponents(true)}
                              >
                                Add Component
                              </Button>
                            </Grid.Column>
                          </>
                        ) : null}
                        {formData?.jobStatusId > 2 ? (
                          <>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 30,
                                marginBottom: 15,
                              }}
                            >
                              <Header
                                as="h5"
                                style={{
                                  color: "#66cc23",
                                }}
                              >
                                Inventory Components
                              </Header>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 35,
                                marginBottom: 15,
                              }}
                            ></Grid.Column>
                          </>
                        ) : null}
                        <Grid.Column
                          width={16}
                          style={{
                            padding: "0px 17px",
                          }}
                        >
                          {formData?.inventoryComponents?.map((data, index) => {
                            return (
                              <div key={index}>
                                <Card
                                  style={{
                                    width: "100%",
                                  }}
                                  fluid
                                  color="green"
                                >
                                  <Card.Content>
                                    <Grid>
                                      <Grid.Column width={11}>
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: 14,
                                          }}
                                        >
                                          {`${data.barcode}`}
                                        </span>
                                        <Grid>
                                          <Grid.Column width={3}>
                                            <div
                                              style={{
                                                marginTop: 10,
                                                width: 75,
                                                height: 75,
                                              }}
                                            >
                                              <Image
                                                style={{
                                                  width: 75,
                                                  height: 75,
                                                }}
                                                src={
                                                  data.image128
                                                    ? `data:image/jpeg;base64,${data.image128}`
                                                    : productDefaultImage
                                                }
                                              />
                                            </div>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={13}
                                            style={{
                                              paddingTop: 35,
                                              paddingLeft: "1.5rem",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: 14,
                                              }}
                                            >
                                              {`${data.name}`}
                                            </span>
                                          </Grid.Column>
                                        </Grid>
                                      </Grid.Column>
                                      <Grid.Column width={4}>
                                        <Form
                                          style={{
                                            textAlign: "center",
                                            marginTop: 10,
                                          }}
                                        >
                                          <label>Quantity</label>
                                          <Form.Field>
                                            <NumericInputBox
                                              value={data.quantity}
                                              name="quantity"
                                              min={1}
                                              max={10000}
                                              maxLength={6}
                                              precision={0}
                                              step={1}
                                              onChangeHandler={(value) =>
                                                handleOtherComponentQuantityChange(
                                                  index,
                                                  value
                                                )
                                              }
                                              onKeyDownHandler={(e) => {
                                                if (e.key === "Enter")
                                                  e.preventDefault();
                                              }}
                                            />
                                          </Form.Field>
                                        </Form>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={1}
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          paddingTop: 45,
                                          textAlign: "center",
                                        }}
                                      >
                                        <Popup
                                          position="top center"
                                          trigger={
                                            <Icon
                                              name="trash"
                                              size="big"
                                              style={{
                                                cursor: "pointer",
                                                color: "red",
                                                marginLeft: -15,
                                                marginTop: 10,
                                              }}
                                              onClick={() =>
                                                handleOtherComponentQuantityChange(
                                                  index,
                                                  0
                                                )
                                              }
                                            />
                                          }
                                          content="Remove"
                                          style={{
                                            fontSize: 12,
                                            opacity: 0.7,
                                          }}
                                          inverted
                                        />
                                      </Grid.Column>
                                    </Grid>
                                  </Card.Content>
                                </Card>
                              </div>
                            );
                          })}
                        </Grid.Column>
                        {formData?.jobStatusId === 2 ? (
                          <>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 30,
                                marginBottom: 15,
                              }}
                            >
                              <Header
                                as="h5"
                                style={{
                                  color: "#66cc23",
                                }}
                              >
                                Non-BOA Items
                              </Header>
                            </Grid.Column>
                            <Grid.Column
                              width={3}
                              style={{
                                marginTop: 35,
                                marginBottom: 15,
                              }}
                            ></Grid.Column>
                            <Grid.Column
                              width={5}
                              style={{
                                marginTop: 25,
                                marginBottom: 15,
                                paddingRight: 15,
                              }}
                            >
                              <Button
                                className="active-status"
                                size="small"
                                style={{
                                  width: "100%",
                                  backgroundColor: "rgb(102, 204, 35)",
                                  color: "#fff",
                                  marginLeft: "auto",
                                }}
                                onClick={() => {
                                  setcCustomProductText("");
                                  setOpenCustomItem(true);
                                }}
                              >
                                Add Non-BOA Item
                              </Button>
                            </Grid.Column>
                          </>
                        ) : null}
                        {formData?.jobStatusId > 2 ? (
                          <>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 30,
                                marginBottom: 15,
                              }}
                            >
                              <Header
                                as="h5"
                                style={{
                                  color: "#66cc23",
                                }}
                              >
                                Non-BOA Items
                              </Header>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 35,
                                marginBottom: 15,
                              }}
                            ></Grid.Column>
                          </>
                        ) : null}
                        <Grid.Column
                          width={16}
                          style={{
                            padding: "0px 17px",
                          }}
                        >
                          {formData?.inventoryNonBOA?.map((data, index) => {
                            return (
                              <div key={index}>
                                <Card
                                  style={{
                                    width: "100%",
                                  }}
                                  fluid
                                  color="green"
                                >
                                  <Card.Content>
                                    <Grid>
                                      <Grid.Column
                                        width={8}
                                        style={{
                                          paddingTop: 20,
                                          paddingBottom: 20,
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: 16,
                                          }}
                                        >
                                          {`${data.description}`}
                                        </span>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={3}
                                        style={{
                                          paddingBottom: 20,
                                          paddingRight: 0,
                                        }}
                                      >
                                        <Form
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          <label>Quantity</label>
                                          <Form.Field>
                                            <NumericInputBox
                                              value={data.quantity}
                                              name="quantity"
                                              min={0}
                                              max={10000}
                                              maxLength={6}
                                              precision={0}
                                              step={1}
                                              onChangeHandler={(value) =>
                                                handleCustomComponentQuantityChange(
                                                  index,
                                                  value
                                                )
                                              }
                                              onKeyDownHandler={(e) => {
                                                if (e.key === "Enter")
                                                  e.preventDefault();
                                              }}
                                            />
                                          </Form.Field>
                                        </Form>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={4}
                                        style={{ paddingBottom: 20 }}
                                      >
                                        <Form
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          <label>Sell Price</label>
                                          <Form.Field>
                                            <NumericInputBox
                                              value={data.sellPrice}
                                              name="quantity"
                                              min={0}
                                              max={1000000}
                                              maxLength={6}
                                              precision={2}
                                              step={1}
                                              onChangeHandler={(value) =>
                                                handleCustomComponentPriceChange(
                                                  index,
                                                  value
                                                )
                                              }
                                              onKeyDownHandler={(e) => {
                                                if (e.key === "Enter")
                                                  e.preventDefault();
                                              }}
                                            />
                                          </Form.Field>
                                        </Form>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={1}
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          paddingTop: 45,
                                          textAlign: "center",
                                        }}
                                      >
                                        <Popup
                                          position="top center"
                                          trigger={
                                            <Icon
                                              name="trash"
                                              size="big"
                                              style={{
                                                cursor: "pointer",
                                                color: "red",
                                                marginLeft: -15,
                                              }}
                                              onClick={() =>
                                                handleCustomComponentQuantityChange(
                                                  index,
                                                  -1
                                                )
                                              }
                                            />
                                          }
                                          content="Remove"
                                          style={{
                                            fontSize: 12,
                                            opacity: 0.7,
                                          }}
                                          inverted
                                        />
                                      </Grid.Column>
                                    </Grid>
                                  </Card.Content>
                                </Card>
                              </div>
                            );
                          })}
                        </Grid.Column>
                        {formData?.jobStatusId > 1 ? (
                          <>
                            <Grid.Column
                              width={16}
                              style={{
                                marginBottom: 15,
                                paddingTop: "1.5rem",
                                paddingBottom: "0px",
                              }}
                            >
                              <Header
                                as="h5"
                                style={{
                                  color: "#66cc23",
                                }}
                              >
                                Charges
                              </Header>
                            </Grid.Column>
                            <Grid.Column
                              width={7}
                              disabled={formData?.jobStatusId > 2}
                              style={{
                                paddingTop: 0,
                              }}
                            >
                              <Form.Field>
                                <label>Travel</label>
                                <Input
                                  type="number"
                                  label={{
                                    basic: true,
                                    content:
                                      userCountryCode === "US" ? "mi" : "kms",
                                  }}
                                  labelPosition="right"
                                  value={formData?.distanceTravelled}
                                  onChange={(e, data) =>
                                    handleChange(
                                      "distanceTravelled",
                                      data.value
                                    )
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                            <Grid.Column
                              width={4}
                              disabled={formData?.jobStatusId > 2}
                              style={{
                                paddingTop: 0,
                              }}
                            >
                              <Form.Field style={{ cursor: "not-allowed" }}>
                                <label>Labour</label>
                                <Input
                                  style={{
                                    cursor: "not-allowed",
                                    pointerEvents: "none",
                                    opacity: 0.8,
                                    width: "60%",
                                  }}
                                  label={{
                                    basic: true,
                                    content: "hrs",
                                  }}
                                  labelPosition="right"
                                  value={formData?.hoursLabour}
                                  onChange={(e, data) =>
                                    handleChange("hoursLabour", data.value)
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                            <Grid.Column
                              width={5}
                              style={{
                                marginTop: 25,
                                marginBottom: 15,
                                paddingRight: 15,
                              }}
                            >
                              <Button
                                className="active-status"
                                size="small"
                                style={{
                                  width: "100%",
                                  backgroundColor: "rgb(102, 204, 35)",
                                  color: "#fff",
                                  marginLeft: "auto",
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                                onClick={() => setOpenManualTimeEntry(true)}
                              >
                                Add manual time entry
                              </Button>
                            </Grid.Column>
                            <Grid.Column
                              width={7}
                              disabled={formData?.jobStatusId > 2}
                              style={{ paddingTop: 0 }}
                            ></Grid.Column>
                            <Grid.Column
                              width={9}
                              disabled={formData?.jobStatusId > 2}
                            >
                              <Form.Field style={{ cursor: "not-allowed" }}>
                                <label>Entries</label>
                                {timeLogs?.map((data) => {
                                  if (data.type === "manual") {
                                    return (
                                      <>
                                        <hr />
                                        <Grid
                                          style={{
                                            paddingTop: 18,
                                            paddingBottom: 5,
                                          }}
                                        >
                                          <Grid.Column
                                            width={16}
                                            style={{
                                              paddingTop: 0,
                                              paddingBottom: 0,
                                            }}
                                          >
                                            <span>{data.createdBy}</span>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={4}
                                            style={{
                                              paddingTop: 0,
                                              paddingBottom: 10,
                                            }}
                                          >
                                            <span>
                                              {data.formattedDateTimeStart}
                                            </span>
                                            <br />
                                            <span>
                                              {data.formattedDateStart}
                                            </span>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={1}
                                            style={{
                                              paddingTop: 0,
                                              paddingBottom: 10,
                                              paddingLeft: 0,
                                              paddingRight: 0,
                                              textAlign: "center",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <span>-</span>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={5}
                                            style={{
                                              paddingTop: 0,
                                              paddingBottom: 10,
                                              paddingLeft: 10,
                                            }}
                                          >
                                            <span>
                                              {(data.timeSpanMins / 60).toFixed(
                                                2
                                              )}{" "}
                                              hr
                                            </span>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={6}
                                            style={{
                                              paddingTop: 0,
                                              paddingBottom: 10,
                                              paddingLeft: 0,
                                              paddingRight: 0,
                                            }}
                                          >
                                            {data.notes !== null &&
                                              data.notes !== "" && (
                                                <>
                                                  <span>{data.notes}</span>{" "}
                                                  <br />
                                                </>
                                              )}
                                          </Grid.Column>
                                        </Grid>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <hr />
                                        <Grid
                                          style={{
                                            paddingTop: 18,
                                            paddingBottom: 5,
                                          }}
                                        >
                                          <Grid.Column
                                            width={16}
                                            style={{
                                              paddingTop: 0,
                                              paddingBottom: 0,
                                            }}
                                          >
                                            <span>{data.createdBy}</span>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={4}
                                            style={{
                                              paddingTop: 0,
                                              paddingBottom: 0,
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <span>
                                              {data.formattedDateTimeStart}
                                            </span>
                                            <br />
                                            <span>
                                              {data.formattedDateStart}
                                            </span>{" "}
                                          </Grid.Column>
                                          <Grid.Column
                                            width={1}
                                            style={{
                                              paddingTop: 0,
                                              paddingBottom: 10,
                                              paddingLeft: 0,
                                              paddingRight: 0,
                                              textAlign: "center",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <span>-</span>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={5}
                                            style={{
                                              paddingTop: 0,
                                              paddingBottom: 10,
                                              paddingLeft: 10,
                                            }}
                                          >
                                            <span>
                                              {data.formattedDateTimeEnd}
                                            </span>
                                            <br />
                                            <span>
                                              {data.formattedDateEnd}
                                            </span>{" "}
                                          </Grid.Column>
                                          <Grid.Column
                                            width={6}
                                            style={{
                                              paddingTop: 0,
                                              paddingBottom: 10,
                                              paddingLeft: 0,
                                              paddingRight: 0,
                                            }}
                                          >
                                            {data.notes !== null &&
                                            data.notes !== "" ? (
                                              <span>{data.notes}</span>
                                            ) : (
                                              <span>Automatic entry</span>
                                            )}
                                          </Grid.Column>
                                        </Grid>
                                      </>
                                    );
                                  }
                                })}
                              </Form.Field>
                            </Grid.Column>
                          </>
                        ) : null}
                      </Grid.Row>
                      {formData?.jobStatusId > 1 ? (
                        <Grid.Row style={{ padding: 0 }}>
                          <Grid.Column
                            width={16}
                            style={{
                              marginTop: 30,
                              marginBottom: 15,
                            }}
                            disabled={formData?.jobStatusId > 2}
                          >
                            <Header
                              as="h5"
                              style={{
                                color: "#66cc23",
                              }}
                            >
                              Job Notes
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{
                              marginTop: -5,
                              marginBottom: 15,
                            }}
                            disabled={formData?.jobStatusId > 2}
                          >
                            <TextArea
                              onChange={(e, data) =>
                                handleChange("notes", data.value)
                              }
                              value={formData?.notes}
                            />
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{
                              marginTop: 5,
                              marginBottom: 15,
                            }}
                          >
                            <Header
                              as="h5"
                              style={{
                                color: "#66cc23",
                              }}
                            >
                              Additional Images
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{
                              marginTop: -5,
                              marginBottom: 15,
                            }}
                          >
                            <div
                              className="ui image"
                              style={{ marginBottom: 10 }}
                            >
                              {imageLoading ? (
                                <Button disabled size="mini">
                                  Uploading...
                                </Button>
                              ) : (
                                <JobImageUploader
                                  disabled={formData?.jobStatusId > 2}
                                  setImage={handleFileChange}
                                />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{
                              marginTop: -5,
                              marginBottom: 15,
                            }}
                          >
                            <Grid style={{ padding: 0, margin: 0 }}>
                              {jobAdditionalImages.map((data, index) => {
                                return (
                                  <Grid.Column
                                    width={4}
                                    style={{ padding: 0, margin: 0 }}
                                  >
                                    <div
                                      className="ui image"
                                      style={{
                                        height: 130,
                                        width: 130,
                                        marginTop: 10,
                                      }}
                                    >
                                      <img
                                        onClick={() =>
                                          openAdditionalImageViewer(index)
                                        }
                                        src={data.filePath}
                                        alt="Image Preview"
                                        className="bomImage"
                                        style={{
                                          marginTop: "auto",
                                          marginBottom: "auto",
                                          cursor: "pointer",
                                        }}
                                      />
                                      {formData?.jobStatusId < 3 && (
                                        <Button
                                          circular
                                          icon="settings"
                                          style={{
                                            position: "absolute",
                                            left: 115,
                                            top: -12,
                                            cursor: "pointer",
                                            padding: "4px 9px 7px 9px",
                                          }}
                                          onClick={() => {
                                            setSelectefFile(data);
                                            setDeleteFileOpen(true);
                                          }}
                                        >
                                          x
                                        </Button>
                                      )}
                                    </div>
                                  </Grid.Column>
                                );
                              })}
                            </Grid>
                          </Grid.Column>
                        </Grid.Row>
                      ) : null}
                    </Grid>
                  </Form>
                </div>
              </div>
            ) : dataLoading ? (
              <Skeleton count={30} />
            ) : null}
          </Grid.Column>
          <Grid.Column
            computer={4}
            tablet={16}
            mobile={16}
            style={{ paddingLeft: 0 }}
          >
            {selectedData ? (
              <div className="job-info" style={{ paddingLeft: 10 }}>
                <Grid style={{ marginTop: 0 }}>
                  <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column
                      width={16}
                      style={{
                        textAlign: "center",
                        marginBottom: 0,
                      }}
                    >
                      <div
                        className="ui image"
                        style={{
                          height: 150,
                          width: 150,
                          zIndex: 2,
                          marginTop: -20,
                        }}
                      >
                        {formData?.makeId !== 0 && (
                          <img
                            src={assetImage}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        )}
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={2}
                      mobile={2}
                      style={{ marginTop: 10 }}
                    >
                      {formData?.jobStatusId > 2 ? (
                        <div onClick={() => handleOpenAssetDetails()}>
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{
                              width: 18,
                              marginTop: -8.5,
                              transform: `rotate(${
                                clicked ? "90deg" : "0deg"
                              })`,
                              transition: "transform 0.5s ease",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      ) : (
                        <Image
                          width={18}
                          src={rightArrow}
                          wrapped
                          style={{ marginTop: -12.5 }}
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column
                      computer={13}
                      tablet={14}
                      mobile={14}
                      style={{ marginTop: 10 }}
                    >
                      <div disabled={formData?.jobStatusId > 2}>
                        <Header
                          as="h5"
                          style={{ marginLeft: -30, marginTop: -1 }}
                        >
                          Asset Details
                        </Header>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={16} style={{ paddingTop: 0 }}>
                      <div
                        className="asset-details"
                        disabled={formData?.jobStatusId > 2}
                        style={{
                          paddingTop:
                            clicked || formData?.jobStatusId <= 2 ? 10 : 0,
                          height:
                            clicked || formData?.jobStatusId <= 2 ? 500 : 0,
                          visibility:
                            clicked || formData?.jobStatusId <= 2
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        <Form size="tiny">
                          <Form.Field>
                            <label>Asset ID</label>
                            <Input
                              value={formData?.assetTextId}
                              onChange={(e, data) =>
                                handleChange("assetTextId", data.value)
                              }
                              placeholder="Asset ID / Plant Number / Rego"
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Asset Serial Number</label>
                            <Input
                              value={formData?.assetSerialNumber}
                              onChange={(e, data) =>
                                handleChange("assetSerialNumber", data.value)
                              }
                              placeholder="Serial Number"
                            />
                          </Form.Field>
                          <Form.Field required={formData?.jobStatusId > 1}>
                            <Grid columns={2} style={{ padding: 0 }}>
                              <Grid.Column
                                width={12}
                                style={{ paddingRight: 0 }}
                              >
                                <AssetSearch
                                  enable={formData?.makeId !== 0}
                                  className="search-box"
                                  loading={assetLoading}
                                  placeholder={
                                    assetLoading ? "" : "Search Asset Details"
                                  }
                                  onResultSelect={handleResultSelect}
                                  onSearchChange={handleAssetSearch}
                                  noResultsMessage={
                                    assetLoading
                                      ? ""
                                      : "No direct matches found."
                                  }
                                  results={assetList}
                                  value={assetSearch}
                                />
                              </Grid.Column>
                              <Grid.Column
                                width={4}
                                style={{
                                  lineHeight: "1",
                                  textAlign: "center",
                                  paddingLeft: 5,
                                }}
                              >
                                <Button
                                  className={
                                    formData?.makeId === 0 && "active-status"
                                  }
                                  style={{
                                    fontSize: 12,
                                    lineHeight: "1.1",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  onClick={() =>
                                    handleChange(
                                      "makeId",
                                      formData?.makeId === 0 ? null : 0
                                    )
                                  }
                                >
                                  Input Manually
                                </Button>
                              </Grid.Column>
                            </Grid>
                          </Form.Field>
                          {isLoading && formData?.jobStatusId <= 2 ? (
                            <Dimmer active inverted>
                              <Loader size="large"></Loader>
                            </Dimmer>
                          ) : (
                            <>
                              <Form.Field
                                style={{
                                  opacity: formData?.makeId === 0 ? "1" : "0.7",
                                }}
                              >
                                <label>Manufacturer</label>
                                <Input
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    pointerEvents:
                                      formData?.makeId === 0 ? "" : "none",
                                    cursor:
                                      formData?.makeId === 0
                                        ? "none"
                                        : "not-allowed",
                                  }}
                                  onChange={(e, data) =>
                                    handleChange("makeName", data.value)
                                  }
                                  value={formData?.makeName}
                                />
                              </Form.Field>
                              <Form.Field
                                style={{
                                  opacity: formData?.makeId === 0 ? "1" : "0.7",
                                }}
                              >
                                <label>Equipment Type</label>
                                <Input
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    pointerEvents:
                                      formData?.makeId === 0 ? "" : "none",
                                    cursor:
                                      formData?.makeId === 0
                                        ? "none"
                                        : "not-allowed",
                                  }}
                                  onChange={(e, data) =>
                                    handleChange(
                                      "equipmentTypeName",
                                      data.value
                                    )
                                  }
                                  value={formData?.equipmentTypeName}
                                />
                              </Form.Field>
                              <Form.Field
                                style={{
                                  opacity: formData?.makeId === 0 ? "1" : "0.7",
                                }}
                              >
                                <label>Asset Model</label>
                                <Input
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    pointerEvents:
                                      formData?.makeId === 0 ? "" : "none",
                                    cursor:
                                      formData?.makeId === 0
                                        ? "none"
                                        : "not-allowed",
                                  }}
                                  onChange={(e, data) =>
                                    handleChange("model", data.value)
                                  }
                                  value={formData?.model}
                                />
                              </Form.Field>
                            </>
                          )}
                          <Form.Field>
                            <label>Asset Hours/Mileage</label>
                            <Input
                              type="number"
                              label={
                                <Dropdown
                                  defaultValue="hrs"
                                  value={formData?.assetPropUnit}
                                  options={options}
                                  onChange={(e, data) =>
                                    handleChange("assetPropUnit", data.value)
                                  }
                                />
                              }
                              labelPosition="right"
                              value={formData?.assetPropValue}
                              onChange={(e, data) =>
                                handleChange("assetPropValue", data.value)
                              }
                            />
                          </Form.Field>
                        </Form>
                      </div>
                    </Grid.Column>
                    {formData?.jobStatusId === 5 &&
                    invoice?.contactId === "" ? null : formData?.jobStatusId >
                        2 &&
                      localStorage.getItem("isXeroConnected") === "true" ? (
                      <>
                        {isLoading && formData?.jobStatusId > 2 ? (
                          <Loader active size="large"></Loader>
                        ) : null}
                        <Grid.Column
                          computer={3}
                          tablet={2}
                          mobile={2}
                          style={{ marginTop: 25 }}
                        >
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{ marginTop: -12.5 }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={13}
                          tablet={14}
                          mobile={14}
                          style={{ marginTop: 25 }}
                        >
                          <Header as="h5" style={{ marginLeft: -30 }}>
                            Invoice
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          width={16}
                          style={{
                            paddingTop: 8,
                            paddingBottom: 25,
                            opacity:
                              isLoading && formData?.jobStatusId > 2 ? 0.3 : 1,
                          }}
                        >
                          <span style={{ fontSize: 18 }}>
                            {formData?.companyName}
                          </span>
                          <Form size="tiny" style={{ marginTop: 15 }}>
                            <Form.Field>
                              <label>Search Xero Customer</label>
                              <Dropdown
                                disabled={formData?.jobStatusId >= 4}
                                search
                                selection
                                options={xeroCustomerList}
                                value={invoice?.contactId}
                                onChange={(e, data) =>
                                  handleInvoiceChange("contactId", data.value)
                                }
                                style={{
                                  width: "100%",
                                }}
                                icon={
                                  formData?.jobStatusId >= 4 ? "" : "search"
                                }
                              />
                            </Form.Field>
                            <Form.Field disabled={invoice?.contactId === ""}>
                              <label>Invoice</label>
                              <Grid columns={2} style={{ padding: 0 }}>
                                <Grid.Column
                                  width={formData?.jobStatusId >= 4 ? 16 : 10}
                                  style={{
                                    paddingRight:
                                      formData?.jobStatusId >= 4 ? "1rem" : 0,
                                    marginRight: 0,
                                  }}
                                >
                                  {invoice.invoiceID === null ? (
                                    <Input
                                      disabled={formData?.jobStatusId >= 4}
                                      placeholder="Create New Invoice"
                                      value={invoice?.invoiceID}
                                      onChange={(e, data) =>
                                        handleInvoiceChange(
                                          "invoiceID",
                                          data.value
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        cursor: "not-allowed",
                                        pointerEvents: "none",
                                      }}
                                    />
                                  ) : (
                                    <Dropdown
                                      disabled={formData?.jobStatusId >= 4}
                                      noResultsMessage="No draft invoices available"
                                      icon={
                                        formData?.jobStatusId >= 4
                                          ? ""
                                          : "search"
                                      }
                                      search
                                      selection
                                      options={
                                        formData?.jobStatusId > 3 &&
                                        invoice.contactId !== ""
                                          ? xeroInvoicesList
                                          : xeroDraftInvoicesList
                                      }
                                      value={invoice?.invoiceID}
                                      onChange={(e, data) =>
                                        handleInvoiceChange(
                                          "invoiceID",
                                          data.value
                                        )
                                      }
                                      style={{ minWidth: "100%" }}
                                    />
                                  )}
                                </Grid.Column>
                                {formData?.jobStatusId >= 4 ? null : (
                                  <Grid.Column
                                    width={6}
                                    style={{
                                      lineHeight: "1",
                                      textAlign: "center",
                                      paddingLeft: 10,
                                    }}
                                  >
                                    <Button
                                      style={{
                                        fontSize: 12,
                                        lineHeight: "1.1",
                                        fontWeight: "bold",
                                        padding: "5px 5px",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                      onClick={() =>
                                        handleInvoiceChange(
                                          "invoiceID",
                                          invoice.invoiceID === null ? "" : null
                                        )
                                      }
                                    >
                                      {invoice.invoiceID === null
                                        ? "Add to existing invoice"
                                        : "Create New Invoice"}
                                    </Button>
                                  </Grid.Column>
                                )}
                              </Grid>
                            </Form.Field>
                            {formData?.jobStatusId >= 4 ? (
                              <Form.Field>
                                <label>Invoice Status</label>
                                <Input
                                  disabled={formData?.jobStatusId >= 4}
                                  value={
                                    xeroInvoicesList.find(
                                      (inv) =>
                                        inv.invoiceID ===
                                        selectedData?.jobDetails?.invoiceId
                                    )?.status === "AUTHORISED"
                                      ? "COMPLETED"
                                      : xeroInvoicesList.find(
                                          (inv) =>
                                            inv.invoiceID ===
                                            selectedData?.jobDetails?.invoiceId
                                        )?.status
                                  }
                                  style={{
                                    width: "100%",
                                    opacity: 1,
                                  }}
                                />
                              </Form.Field>
                            ) : (
                              <Form.Field disabled={invoice?.contactId === ""}>
                                <label>Invoice Status</label>
                                <Select
                                  disabled={formData?.jobStatusId >= 4}
                                  options={invoiceStatuses}
                                  value={invoice?.invoiceStatus}
                                  onChange={(e, data) =>
                                    handleInvoiceChange(
                                      "invoiceStatus",
                                      data.value
                                    )
                                  }
                                />
                              </Form.Field>
                            )}
                          </Form>
                        </Grid.Column>
                        {formData?.jobStatusId >= 4 ? null : (
                          <>
                            <Grid.Column
                              width={16}
                              style={{
                                paddingRight: "1rem",
                                paddingBottom: 10,
                              }}
                            >
                              <Button
                                disabled={invoice?.contactId === ""}
                                onClick={() => setDiscardInvoiceOpen(true)}
                                color="youtube"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  fontSize: 14,
                                }}
                              >
                                Discard Changes
                              </Button>
                            </Grid.Column>
                            <Grid.Column
                              width={16}
                              style={{
                                paddingLeft: "1rem",
                                paddingBottom: 10,
                              }}
                            >
                              <Button
                                disabled={invoice?.contactId === ""}
                                onClick={() => handleGenerateInvoice()}
                                style={{
                                  color: "#FFFFFF",
                                  backgroundColor: "#11B5EA",
                                  width: "100%",
                                  height: "100%",
                                  fontSize: 14,
                                  padding: 0,
                                }}
                              >
                                <img
                                  src={xeroIcon}
                                  style={{
                                    marginLeft: -8,
                                    marginRight: "auto",
                                    maxWidth: 40,
                                    maxHeight: 40,
                                  }}
                                />
                                Invoice
                              </Button>
                            </Grid.Column>
                          </>
                        )}
                        {formData?.jobStatusId > 2 &&
                        selectedData?.jobDetails?.invoiceId ? (
                          <>
                            <Grid.Column
                              width={16}
                              style={{
                                paddingLeft: 18,
                                marginTop: -10,
                                paddingBottom: 10,
                              }}
                            >
                              <a
                                onClick={() => handleGenerateXeroPDF()}
                                style={{ cursor: "pointer" }}
                              >
                                Xero Invoice:{" "}
                                {
                                  xeroInvoicesList.find(
                                    (inv) =>
                                      inv.invoiceID ===
                                      selectedData?.jobDetails?.invoiceId
                                  )?.invoiceNumber
                                }
                              </a>
                            </Grid.Column>
                            {xeroInvoicesList.find(
                              (inv) =>
                                inv.invoiceID ===
                                selectedData?.jobDetails?.invoiceId
                            )?.status ? (
                              <Grid.Column
                                width={16}
                                style={{
                                  paddingLeft: "1rem",
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                }}
                              >
                                {xeroInvoicesList.find(
                                  (inv) =>
                                    inv.invoiceID ===
                                    selectedData?.jobDetails?.invoiceId
                                )?.status === "DRAFT" ? (
                                  <Button
                                    disabled={invoice?.contactId === ""}
                                    onClick={() => onResendInvoice()}
                                    style={{
                                      color: "#FFFFFF",
                                      backgroundColor: "#11B5EA",
                                      width: "100%",
                                      height: "100%",
                                      fontSize: 14,
                                      padding: 0,
                                    }}
                                  >
                                    <img
                                      src={xeroIcon}
                                      style={{
                                        marginLeft: -8,
                                        marginRight: "auto",
                                        maxWidth: 40,
                                        maxHeight: 40,
                                      }}
                                    />
                                    Complete and Send Invoice
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={invoice?.contactId === ""}
                                    onClick={() => onResendInvoice()}
                                    style={{
                                      color: "#FFFFFF",
                                      backgroundColor: "#11B5EA",
                                      width: "100%",
                                      height: "100%",
                                      fontSize: 14,
                                      padding: 0,
                                    }}
                                  >
                                    <img
                                      src={xeroIcon}
                                      style={{
                                        marginLeft: -8,
                                        marginRight: "auto",
                                        maxWidth: 40,
                                        maxHeight: 40,
                                      }}
                                    />
                                    Resend Invoice
                                  </Button>
                                )}
                              </Grid.Column>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </Grid.Row>
                </Grid>
              </div>
            ) : dataLoading ? (
              <Skeleton count={30} />
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

export default Jobbing;
